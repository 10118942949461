.okx-validate-input-wrapper {
  display: flex;
  width: 100%;
}

.okx-validate-input-wrapper .okx-validate-input-holder {
  flex: 1 1 auto;
  /* padding-bottom: 5px; */
}

.okx-validate-input-wrapper .okx-validate-input-holder ion-datetime {
  --padding-start: 0;
  --padding-top: 15px;
}
.okx-validate-input-wrapper .okx-validate-input-holder p {
  font-family: var(--ion-default-font-light);
  font-size: 12px;
  font-weight: bold;
  /* margin: 21px 0 0 0; */
  display: flex;
  /* padding-left: 2px; */
  color: inherit;
}

.okx-validate-input-wrapper .okx-validate-input-holder ion-label,
.okx-validate-input-holder > .date-picker-wrapper .date-picker-modal-label {
  font-family: var(--ion-default-font-light);
  font-size: 13px;
  font-weight: bold;
}

.okx-validate-input-holder > .date-picker-wrapper .date-picker-modal-label {
  font-size: 12px;
}

.okx-validate-input-wrapper ion-button {
  flex: 1 0 auto;
  min-width: 32px;
  /* padding-top: 10px; */
}

.date-picker-wrapper.modal .data-picker-input {
  padding-top: 15px;
}

.date-picker-wrapper.modal ion-label {
  font-size: 8px;
}

.date-picker-modal-label {
  font-size: 9px;
  color: var(--ion-color-dark);
  font-weight: normal;
  /* margin-left: 2px; */
  transition: 0.1s ease-in;
}

.mbsc-mobiscroll .mbsc-fr-hdr,
.mbsc-mobiscroll .mbsc-fr-btn {
  color: var(--ion-color-primary);
}

.mbsc-mobiscroll .mbsc-cal-days > div {
  color: var(--ion-color-primary);
  border-color: var(--ion-color-primary);
}

.mbsc-mobiscroll.mbsc-no-touch:not(.mbsc-ev-cal)
  .mbsc-cal-cell:not(.mbsc-disabled):hover
  .mbsc-cal-cell-txt,
.mbsc-mobiscroll.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover,
.mbsc-mobiscroll .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
  background: var(--ion-color-light-tint);
}

.mbsc-mobiscroll .mbsc-fr-btn.mbsc-active,
.mbsc-mobiscroll.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover {
  background: var(--ion-color-light);
}

.mbsc-mobiscroll
  .mbsc-cal-c
  .mbsc-cal
  .mbsc-cal-body
  .mbsc-cal-row
  .mbsc-selected
  .mbsc-cal-cell-txt {
  background: var(--ion-color-primary);
}

.date-picker-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 5px 0 10px 0;
}

.date-picker-label {
  font-size: 9px;
  font-weight: normal;
  color: var(--okx-normal-text-color);
  color: #959595;
  transition: 0.1s ease-in;
}

.date-picker-label--red {
  color: var(--ion-color-primary);
}

.data-picker-input {
  border: 0;
  outline: 0;
  color: var(--ion-color-dark);
  padding: 10px 8px 10px 0;
}

.mbsc-material
  .mbsc-cal-c
  .mbsc-cal
  .mbsc-cal-body
  .mbsc-cal-row
  .mbsc-selected
  .mbsc-cal-cell-txt {
  /* background: var(--ion-color-primary); */
  background: rgba(255, 26, 75, 0.9);
  color: #fff;
}

.mbsc-material .mbsc-fr-btn {
  color: var(--ion-color-primary);
}

.mbsc-ios .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
  background: var(--ion-color-primary);
  border-color: var(--ion-color-primary);
}

.mbsc-mobiscroll .mbsc-sc-whl-l {
  display: none;
}

.mbsc-comp.time-picker {
  width: 100%;
  color: var(--ion-color-dark);
  padding: 10px 8px 10px 0;
  border: 0;
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.13);
  border-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
  );
  outline: none;
}

.mbsc-comp.time-picker::-webkit-input-placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-comp.time-picker:-ms-input-placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-comp.time-picker::placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-ios .mbsc-sc-itm-sel,
.mbsc-ios .mbsc-sc-whl-gr-3d {
  /* color: #000; */
  color: #3880ff;
  color: var(--ion-color-primary, #3880ff);
}

.mbsc-material .mbsc-sc-lbl {
  color: #3880ff;
  color: var(--ion-color-primary, #3880ff);
}

.mbsc-fr-btn1 .mbsc-fr-btn-e .mbsc-fr-btn {
  color: #3880ff;
  color: var(--ion-color-primary, #3880ff);
}

/* .mbsc-sc-itm .mbsc-btn-e .mbsc-sc-itm-sel {
  color: var(--ion-color-primary,#3880ff);
} */

.mbsc-material .mbsc-fr-btn-cont .mbsc-fr-btn {
  color: #3880ff;
  color: var(--ion-color-primary, #3880ff);
}

.mbsc-fr-btn-cont {
  display: flex !important;
  justify-content: space-between;
}

.mbsc-sc-itm-sel {
  color: #3880ff;
  color: var(--ion-color-primary, #3880ff);
  font-weight: 600;
}

.time-picker-label {
  font-size: 9px;
  color: var(--okx-normal-text-color);
  /* margin-left: 2px; */
}

.mbsc-mobiscroll .mbsc-sc-lbl {
  color: #3880ff;
  color: var(--ion-color-primary, #3880ff);
}

.mbsc-material .mbsc-sc-whl-l {
  display: none;
}

.mbsc-select-input.mbsc-control {
  width: 100%;
  color: var(--ion-color-dark);
  padding: 10px 8px 10px 0;
  border: 0;
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.13);
  border-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
  );
  outline: none;
}

.mbsc-select-input.mbsc-control::-webkit-input-placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-select-input.mbsc-control:-ms-input-placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-select-input.mbsc-control::placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-ios .mbsc-fr-btn {
  color: #3880ff;
  color: var(--ion-color-primary, #3880ff);
  font-size: 17px;
}

.select-picker-label {
  display: block;
  font-size: 9px;
  color: var(--okx-normal-text-color);
  margin-top: 10px;
  font-weight: normal;
}

.select-picker-label--primary {
  color: #3880ff;
  color: var(--ion-color-primary, #3880ff);
}

.select-picker-label.select-picker-label--feedback {
  font-weight: bold;
  margin-left: 0;
}

.select-picker-input--feedback + input.mbsc-select-input.mbsc-control {
  color: var(--ion-color-gray);
  font-size: 10px;
  font-weight: normal;
  border-color: rgba(0, 0, 0, 0.13);
  border-color: var(
    --ion-color-black,
    var(--ion-color-black, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
  );
}

.mbsc-fr-btn-w .mbsc-fr-btn-s {
  border: 0;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-w {
  border: 0;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-cont {
  border: 1px solid
    rgba(0, 0, 0, 0.13);
  border: 1px solid
    var(
      --ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
    );
}

.mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-itm-3d {
  color: #a3a3a3;
}

.mbsc-ios .mbsc-sc-itm-sel {
  color: #000;
  color: #3880ff !important;
  color: var(--ion-color-primary, #3880ff) !important;
}

.validate-modal > .modal-wrapper {
  max-height: 72vh;
  width: 80vw;
  border-radius: 15px;
  text-align: center;
}

.validate-modal > .modal-wrapper > .ion-page {
  min-width: 100%;
  padding: 15px 25px;
  overflow: auto;
}

.validate-modal > .modal-wrapper > .ion-page h2 {
  font-size: 20px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.validate-modal > .modal-wrapper > .ion-page ion-icon {
  position: absolute;
  right: 0;
  font-size: 15px;
}

.validate-modal ion-item {
  --min-height: 20px;
  --inner-padding-end: 0;
}

.modal-content {
  margin-top: 20px;
}

.validate-modal > .modal-wrapper .ion-page .inline-input > ion-text,
.validate-modal > .modal-wrapper .ion-page .inline-input > label {
  flex: 0 0 40%;
}

.validate-modal > .modal-wrapper .ion-page .inline-input > ion-input[name='email'] {
  text-overflow: ellipsis;
}

.web .validate-modal > .modal-wrapper .ion-page {
  padding-top: 60px;
}

.web .validate-modal > .modal-wrapper {
  max-width: var(--okx-bsmodal-width-md);
  width: 100%;
}

.web .validate-modal > .modal-wrapper .validate-modal-closer {
  display: inline-block;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.web .validate-modal > .modal-wrapper .validate-modal-closer ion-icon {
  font-size: 20px;
}

.web .validate-modal > .modal-wrapper .validate-modal-header > br {
  display: none;
}

.web .validate-modal > .modal-wrapper .validate-modal-header {
  text-align: left;
}

.web .validate-modal > .modal-wrapper .validate-modal-header ion-text .title {
  font-weight: bold;
}

.web .validate-modal > .modal-wrapper .validate-modal-header ion-text .small-text {
  color: var(--ion-color-gray);
}

.verify-account-date .date-picker-wrapper {
  margin: 0 !important;
}

.email-validated {
  margin-top: 5px
}
.icomoon {
  font-family: var(--ion-icomoon-font-family);
  font-size: 24px;
}

span > .icomoon {
  font-size: 20px;
}

.icomoon::before {
  display: block;
}

.icomoon.ico-voucher::before {
  content: '\e900';
}

.icomoon.ico-profile::before {
  content: '\e901';
}

.icomoon.ico-basket::before {
  content: '\e902';
}

.icomoon.ico-map::before {
  content: '\e903';
}

.icomoon.ico-home::before {
  content: '\e904';
}

.icomoon.ico-order::before {
  content: '\e905';
}

.icomoon.ico-social::before {
  content: '\e906';
}

.icomoon.ico-orders::before {
  content: '\e907';
}

.icomoon.ico-allergens::before {
  content: '\e908';
}

.icomoon.ico-logout::before {
  content: '\e909';
}

.icomoon.ico-feedback::before {
  content: '\e90a';
}

.icomoon.ico-radio::before {
  content: '\e90b';
}

ion-header.static {
  margin-bottom: -48px;
  z-index: 5;
  padding-top: 0;
  padding-top: var(--ion-safe-area-top, 0);
}

ion-toolbar {
  --border-color: #fff;
}

ion-toolbar.primary-toolbar {
  --min-height: 80px;
  --background: var(--ion-color-white) url(/static/media/logo-main.c2db7137.png) no-repeat 50% 50% /
    auto 85%;
  opacity: 1;
}

.web ion-header:not(.route-order).static {
  position: absolute;
  right: 0;
  background: transparent none;
  min-height: var(--ion-toolbar-min-height);
  height: auto;
  margin: 0;
  z-index: 6;
  pointer-events: none;
}

.web ion-header.static ion-buttons {
  pointer-events: all;
}

.web ion-toolbar.primary-toolbar {
  --background: transparent url(/static/media/logo-main.c2db7137.png) no-repeat 50% 50% / auto
    110%;
}

.web ion-toolbar.primary-toolbar ion-buttons:first-of-type,
.web ion-toolbar.primary-toolbar ion-buttons:last-of-type,
.web ion-toolbar.dynamic-header ion-buttons:first-of-type {
  background-color: rgba(255, 255, 255, 0.5);
}

.web ion-toolbar.primary-toolbar ion-buttons:last-of-type ion-button {
  --padding-top: 12px;
  --padding-end: 12px;
  --padding-bottom: 12px;
  --padding-start: 12px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 50px;
  height: 50px;
}

.web ion-toolbar.primary-toolbar ion-button.image-button {
  display: none;
}

ion-header.static ion-toolbar:last-child {
  --min-height: var(--ion-toolbar-min-height);
}

ion-button.solo-button {
  --padding-start: 6px;
  --padding-end: 6px;
}

ion-button.basket-button {
  /* width: 45px; */
  height: 41px;
}

ion-button.basket-button > div > div > .badge,
ion-button.basket-button > div > div > .badge-small {
  position: absolute;
  bottom: 0;
  width: 20px;
  height: 20px;
  right: -4px;
  z-index: 1;
  padding: 4px 1px;
  font-size: 12px;
  border-radius: 60px;
}

ion-button.basket-button > div > div > .badge-small {
  width: 18px;
  height: 18px;
  right: -2px;
  padding: 2px 0 0 0;
}

ion-button.basket-button > div > .basket-icon-wrapper {
  position: relative;
}

ion-button.basket-button > div > div > ion-icon {
  font-size: 30px;
}

.primary-toolbar .button {
  color: var(--ion-color-dark);
}

.image-button {
  flex: 1 1;
  background: transparent;
  --ripple-color: transparent;
}

@supports (-webkit-overflow-scrolling: touch) {
  /* iOS */
  ion-toolbar.primary-toolbar {
    --background: var(--ion-color-white) url(/static/media/logo-main.c2db7137.png) no-repeat 50%
      65% / auto 100%;
    opacity: 1;
  }
}

.web ion-button.basket-button > div > div > ion-badge.badge-small {
  --ion-color-primary: var(--ion-color-secondary);
  color: var(--ion-color-black);
  font-size: 9px;
  min-width: 15px;
  text-align: center;
  --padding-top: 3px;
  --padding-bottom: 3px;
  --padding-start: 3px;
  --padding-end: 3px;
  width: auto;
  height: auto;
  right: 0;
  border-radius: 8px;
  padding: 3px;
}

.web ion-button.basket-button > div > div:last-child {
  --ion-color-gray: var(--ion-color-secondary);
}

.web .header-ios ion-toolbar:last-of-type,
.web .header-md ion-toolbar:last-of-type {
  /* --border-width: 0 0 0.55px; */
  --border-width: 0 0 0;
}

.menu-button {
  cursor: pointer;
  margin-left: 20px;
  height: 16px;
}

.menu-enabled .list-md {
  padding-top: 0;
  padding-bottom: 0;
}

.drawer-menu .title-default {
  text-transform: uppercase;
  font-weight: bold;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

.drawer-menu ion-header,
.drawer-menu ion-content,
.drawer-menu ion-footer {
  --padding-start: var(--okx-drawer-padding);
  --padding-end: var(--okx-drawer-padding);
  padding-left: var(--okx-drawer-padding);
  padding-right: var(--okx-drawer-padding);
}

.drawer-menu ion-header {
  padding-top: 10px;
  padding-bottom: 10px;
}

.drawer-menu ion-footer {
  padding-top: 5px;
  padding-bottom: 20px;
  position: relative;
}

.drawer-menu ion-footer::before {
  background-image: none;
}

.drawer-menu .nav-logo {
  width: 100%;
  height: 68px;
  background: url(/static/media/logo-main.c2db7137.png) transparent no-repeat 0% 58% / auto 100%;
}

.drawer-menu ion-content {
  --ion-color-base: transparent !important;
  --background: transparent;
}

.drawer-menu .nav-label {
  font-size: var(--ion-font-size);
  text-transform: capitalize;
  color: var(--ion-color-primary-contrast);
}
.drawer-menu .terms-items .nav-label {
  color: rgba(var(--ion-color-white-rgb), 0.6);
  font-weight: normal;
}

.drawer-menu .nav-item {
  --ion-color-base: transparent !important;
  --padding-start: 0;
  --min-height: 24px;
}
.drawer-menu .nav-item .item-detail-icon {
  --oppacity: 0;
}
ion-list.terms-items.ios.list-ios.list-lines-none.list-ios-lines-none.hydrated
  > ion-menu-toggle
  > ion-item
  > ion-label,
ion-list.terms-items.md.list-md.list-lines-none.list-md-lines-none.hydrated
  > ion-menu-toggle
  > ion-item
  > ion-label {
  color: rgba(var(--ion-color-white-rgb), 0.6);
}

.drawer-menu .nav-item.small-text {
  font-weight: normal;
}

.drawer-menu ion-header ion-toolbar:last-child {
  --border-width: 0 0 0;
  --ion-color-base: transparent !important;
}

.drawer-menu ion-footer > ion-label {
  opacity: 0.6;
}

.drawer-menu ion-icon {
  stroke: #fff;
}

.logo-5l > div {
  width: 100px;
  padding-top: 45%;
  background: url(/static/media/5loyalty-logo.994ee3c0.svg) transparent no-repeat 50% 50%;
  background-size: 100% auto;
}

ion-split-pane {
  --side-max-width: var(--okx-drawer-width);
  --side-min-width: var(--okx-drawer-width);
  --side-width: var(--okx-drawer-width);
  --border: none;
}

ion-menu {
  --background: var(--ion-color-secondary);
}

.web ion-menu ion-content::after,
.web ion-menu ion-content::before {
  display: none;
}

.web ion-menu {
  --max-width: var(--okx-drawer-width);
}

ion-footer > ion-label[hidden] {
  display: block !important;
}

.collapse-drawer-icon,
ion-content.log-status {
  display: none;
}

/* .web ion-footer > ion-label[hidden] {
	display: none !important;
} */

.web .drawer-menu ion-header ion-toolbar {
  position: relative;
}

.web .drawer-menu .nav-logo {
  width: 90%;
  cursor: pointer;
  background-size: auto 70%;
  background-position: 0% 50%;
  fill: #fff;
}

.web .drawer-menu .nav-icon {
  font-size: 20px;
}

.web .small-drawer-header {
  padding: 15px 0 0 0;
}

.web .small-drawer-toolbar {
  --background: transparent;
}

ion-toolbar {
  --border-color: transparent !important;
}

.web .small-drawer-header ion-buttons {
  position: absolute;
  top: 1px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
}

.web .small-drawer-header ion-button {
  --padding-top: 12px;
  --padding-end: 12px;
  --padding-bottom: 12px;
  --padding-start: 12px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 48px;
  height: 48px;
  background: var(--ion-color-white);
}

.web .small-drawer-header ion-button ion-icon {
  font-size: 1.8rem;
  color: var(--okx-color-black);
}

.web .collapse-drawer-icon {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
  cursor: pointer;
  font-size: 25px;
  color: var(--ion-color-primary-contrast);
}

.web ion-content.log-status {
  display: block;
  max-height: 115px;
}

.web .log-status-wrap {
  border-radius: var(--okx-box-radius);
  border: 1px solid var(--ion-color-gray);
  overflow: hidden;
}

.web .log-status-wrap ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
}

.web .log-status-wrap ion-item:first-of-type {
  --background: var(--okx-background-color);
  --border-radius: var(--okx-box-radius);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 2;
}

.web .log-status-wrap ion-item:last-of-type {
  border-bottom-left-radius: var(--okx-box-radius);
  border-bottom-right-radius: var(--okx-box-radius);
  --min-height: 35px;
}

.web .log-status-wrap ion-item.logout-box {
  border-top: 1px solid var(--ion-color-gray);
}

.web .log-status-content {
  width: 100%;
  background-color: var(--okx-background-color);
}

.web .log-status-content > ion-text .subtitle {
  margin: 0;
  font-size: var(--ion-font-size);
  font-weight: 500;
  color: var(--ion-color-primary);
}

.web .log-status-content > ion-text .small-text {
  color: var(--ion-color-primary);
  opacity: 0.6;
  display: block;
  margin-top: -2px;
}

.web .log-status-wrap ion-icon {
  color: var(--ion-color-primary);
}

.web .logout-box > ion-label,
.web .logout-box > ion-label > ion-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.web .logout-box > ion-label > ion-icon {
  font-size: 17px;
  margin-right: 5px;
}

.web .logout-box > ion-label > ion-text .normal-text {
  font-size: 15px;
  margin-left: 5px;
}

.web .logout-box {
  margin-top: -40px;
  transition: all 0.3s ease;
}

.web .logout-box.visible {
  margin-top: 0;
}

.web .drawer-menu ion-content:first-of-type {
  --padding-start: 0px;
}

.web .drawer-menu ion-content:first-of-type ion-item {
  --padding-start: var(--okx-drawer-padding);
}

.web .drawer-menu ion-content:first-of-type ion-item.okx-nav-selected > ion-icon {
  background: var(--ion-color-secondary);
  border-radius: 50%;
}
.drawer-menu-order-button {
  margin: 0;
  width: 100%;
}
.web .drawer-menu-order-button {
  padding: 0 0 0 var(--okx-drawer-padding);
}
.web .drawer-menu .nav-item {
  cursor: pointer;
}

.web .drawer-menu .nav-icon,
.drawer-menu .nav-label {
  margin-top: 0;
  margin-bottom: 0;
}

.web .drawer-menu .nav-item.small-text > .nav-icon,
.drawer-menu .nav-item.small-text > .nav-label {
  margin-top: 0;
  margin-bottom: 0;
}

.web .drawer-menu .nav-item.small-text > .nav-icon {
  display: none;
}
.drawer-menu .nav-item.small-text {
  --min-height: 26px;
  margin-left: 10px;
}

.web .drawer-menu ion-content:first-of-type ion-item.small-text.okx-nav-selected > ion-icon {
  background-color: transparent;
}

@media screen and (min-width: 992px) {
  .web .collapse-drawer-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    cursor: pointer;
    font-size: 25px;
  }
}

@media screen and (min-width: 768px) {
  .web .collapse-drawer-icon {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    cursor: pointer;
    font-size: 25px;
  }
}

@media screen and (min-width: 1024px) {
  .web .collapse-drawer-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    cursor: pointer;
    font-size: 25px;
  }
}

.header-md::after {
  background-image: none;
}

.fiveloyalty-logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 0 10px 0;
  justify-content: flex-start;
  margin-left: 5px;
  color: var(--ion-color-secondary);
}
.fiveloyalty-logo-wrapper .small-text {
  color: var(--ion-color-primary-contrast);
}
.web .fiveloyalty-logo-wrapper {
  padding: 15px var(--okx-drawer-padding) 10px;
}
.fiveloyalty-logo-wrapper img {
  height: 30px;
  margin-top: 10px;
}
.web .drawer-menu .nav-title {
  margin: 15px 0 5px calc(5px + var(--okx-drawer-padding));
}
.drawer-menu .nav-title {
  display: block;
  margin: 15px 0 5px 5px;
  color: var(--ion-color-primary-contrast);
}
.drawer-menu .nav-icon {
  height: 15px;
  width: 15px;
  margin: 0 5px 0px 0;
  padding: 5px;
}
.drawer-menu ion-footer {
  background-image: none;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.drawer-menu ion-footer img {
  height: 20px;
}
.drawer-menu ion-footer .footer-link {
  --inner-border-width: 0;
}
.drawer-menu ion-footer::before {
  background-image: none;
}

.content-spinner {
  display: flex;
  /*width: 100%;
    height: 100%;*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 6;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  border-radius: inherit;
}

.transparent {
  background: rgba(0, 0, 0, 0);
}

@media screen {
/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #000000;
  --ion-color-primary-rgb: 26, 24, 36;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #171520;
  --ion-color-primary-tint: #312f3a;

  /** secondary **/
  --ion-color-secondary: #52b347;
  --ion-color-secondary-rgb: 82, 179, 71;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #52b347;
  --ion-color-secondary-tint: #f37338;

  /** tertiary **/
  --ion-color-tertiary: #188339;
  --ion-color-tertiary-rgb: 24, 131, 57;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #157332;
  --ion-color-tertiary-tint: #2f8f4d;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #111;
  --ion-color-dark-tint: #444;

  /** gray **/
  --ion-color-gray: #9b9b9b;
  --ion-color-gray-rgb: 155, 155, 155;
  --ion-color-gray-contrast: #ffffff;
  --ion-color-gray-contrast-rgb: 255, 255, 255;
  --ion-color-gray-shade: #858585;
  --ion-color-gray-tint: #a1a1a1;

  /** greenlight **/
  --ion-color-greenlight: #c1d5ae;
  --ion-color-greenlight-rgb: 193, 213, 174;
  --ion-color-greenlight-contrast: #000000;
  --ion-color-greenlight-contrast-rgb: 0, 0, 0;
  --ion-color-greenlight-shade: #aabb99;
  --ion-color-greenlight-tint: #c7d9b6;

  /** light **/
  --ion-color-light: #ebebeb;
  --ion-color-light-rgb: 235, 235, 235;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d4d4d4;
  --ion-color-light-tint: #f1f1f1;

  /** superlight **/
  --ion-color-superlight: #f4f4f4;
  --ion-color-superlight-rgb: 244, 244, 244;
  --ion-color-superlight-contrast: #000000;
  --ion-color-superlight-contrast-rgb: 0, 0, 0;
  --ion-color-superlight-shade: #ebebeb;
  --ion-color-superlight-tint: #f9f9f9;

  /** white **/
  --ion-color-white: #fff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #222;
  --ion-color-white-contrast-rgb: 34, 34, 34;
  --ion-color-white-shade: #d7d8da;
  --ion-color-white-tint: #f5f6f9;

  /** black **/
  --ion-color-black: #111;
  --ion-color-black-rgb: 17, 17, 17;
  --ion-color-black-contrast: #eee;
  --ion-color-black-contrast-rgb: 238, 238, 238;
  --ion-color-black-shade: #000;
  --ion-color-black-tint: #555;

  /** form **/
  --ion-color-form-label: #b0abab;
  --ion-color-form-label-dark: #1e191a;
  --ion-color-note: #cbc6c6;

  /** custom values **/
  --ion-color-title2: #1073d0;
  --ion-color-box-holder: transparent;
  --ion-separator-color: #e6e6e6;
  --ion-feedback-label: #727272;
  --ion-top-small: 5px;
  --ion-top-medium: 30px;
  --ion-feedback-star: #9ba1a5;

  /** original theme file colors **/
  --ion-headings-font-weight: 300;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;

  --ion-padding: 32px;
  --ion-toolbar-min-height: 48px;

  --ion-card-radius: 20px;
  --ion-item-color: #999;
  --ion-item-background: transparent;
  --ion-text-color: var(--ion-color-primary);
  --ion-circle-color: rgb(232, 231, 233);

  /** fonts **/
  --ion-font-family: 'Roboto';
  --ion-default-font: 'Roboto';
  --ion-default-font-bold: 'Roboto';
  --ion-font-size: 12px;

  /* okx vars */
  --okx-drawer-max-width: 280px;
  --okx-small-drawer-max-width: 90px;
  --okx-drawer-width: var(--okx-drawer-max-width);
  --okx-sidebar-width: var(--okx-drawer-max-width);
  --okx-flex-min: 5px;
  --okx-flex-spacer: 20px;
  --okx-big-label-font-size: 42px;
  --okx-big-label-color: var(--ion-color-primary);
  --okx-big-label-margin: 2px 0;
  --okx-big-label-padding: 0;
  --okx-title-font-size: 20px;
  --okx-title-color: var(--ion-color-primary);
  --okx-title-margin: 0 0 5px 0;
  --okx-title-padding: 0;
  --okx-subtitle-font-size: 16px;
  --okx-subtitle-color: var(--ion-color-primary);
  --okx-subtitle-margin: 25px 0;
  --okx-subtitle-padding: 0;
  --okx-sectiontitle-font-size: 14px;
  --okx-sectiontitle-color: var(--ion-color-primary);
  --okx-sectiontitle-margin: 20px 0;
  --okx-sectiontitle-padding: 0;
  --okx-font-size: var(--ion-font-size);
  --okx-small-text-font-size: 11px;
  --okx-note-font-size: 12px;
  --okx-error-font-size: 11px;
  --okx-error-margin: 0 0 10px;
  --okx-hr-margin: 20px auto;
  --okx-border-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
  );
  --okx-drawer-padding: 32px;
  --okx-box-radius: 10px;
  --okx-box-padding-left: 15px;
  --okx-box-padding-top: 15px;
  --okx-box-padding-right: 15px;
  --okx-box-padding-bottom: 15px;
  --okx-small-radius: 5px;
  --okx-normal-text-color: var(--ion-text-color);
  --okx-light-text-color: var(--ion-color-gray);
  --okx-circle-color: var(--ion-circle-color);
  --okx-incrementer-button-width: 40px;
  --okx-loyalty-badge-width: 40px;
  --okx-loyalty-badge-inner-padding: 4px;
  --okx-loyalty-bar-height: 50px;
  --okx-color-border-dash: #2d2b2b;
  --okx-cancel-button-color: rgba(255, 255, 255, 0.2);
  --okx-scrollbar-handle-color: var(--ion-color-secondary);
  --okx-scrollbar-track-color: var(--ion-color-light);
  --okx-scrollbar-color: var(--okx-scrollbar-handle-color) var(--okx-scrollbar-track-color);
  --okx-color-black: #000;
}

.web {
  --ion-item-border-color: #000;
  --okx-bsmodal-top: 180px;
  --okx-bsmodal-width-lg: 500px;
  --okx-bsmodal-width-md: 420px;
  --okx-bsmodal-width-sm: 390px;
}

}
.ion-color-favorite {
  --ion-color-base: #69bb7b;
  --ion-color-base-rgb: 105, 187, 123;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #5ca56c;
  --ion-color-tint: #78c288;
}

.ion-color-twitter {
  --ion-color-base: #1da1f4;
  --ion-color-base-rgb: 29, 161, 244;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1a8ed7;
  --ion-color-tint: #34aaf5;
}

.ion-color-google {
  --ion-color-base: #dc4a38;
  --ion-color-base-rgb: 220, 74, 56;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #c24131;
  --ion-color-tint: #e05c4c;
}

.ion-color-vimeo {
  --ion-color-base: #23b6ea;
  --ion-color-base-rgb: 35, 182, 234;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1fa0ce;
  --ion-color-tint: #39bdec;
}

.ion-color-facebook {
  --ion-color-base: #3b5998;
  --ion-color-base-rgb: 59, 89, 152;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #344e86;
  --ion-color-tint: #4f6aa2;
}

.ion-color-primary-shade {
  --ion-color-base: var(--ion-color-primary-shade);
  --ion-color-base-rgb: 185, 3, 11;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-gray,
.ion-color-grey {
  --ion-color-base: var(--ion-color-gray);
  --ion-color-base-rgb: var(--ion-color-gray-rgb);
  --ion-color-contrast: var(--ion-color-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray-shade);
  --ion-color-tint: var(--ion-color-gray-tint);
}

.ion-color-superlight {
  --ion-color-base: var(--ion-color-superlight);
  --ion-color-base-rgb: var(--ion-color-superlight-rgb);
  --ion-color-contrast: var(--ion-color-superlight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-superlight-contrast-rgb);
  --ion-color-shade: var(--ion-color-superlight-shade);
  --ion-color-tint: var(--ion-color-superlight-tint);
}

.ion-color-success {
  --ion-color-base: var(--ion-color-success);
  --ion-color-base-rgb: var(--ion-color-success-rgb);
  --ion-color-contrast: var(--ion-color-success-contrast);
  --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
  --ion-color-shade: var(--ion-color-success-shade);
  --ion-color-tint: var(--ion-color-success-tint);
}

.ion-color-warning {
  --ion-color-base: var(--ion-color-warning);
  --ion-color-base-rgb: var(--ion-color-warning-rgb);
  --ion-color-contrast: var(--ion-color-warning-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning-shade);
  --ion-color-tint: var(--ion-color-warning-tint);
}

.ion-color-danger {
  --ion-color-base: var(--ion-color-danger);
  --ion-color-base-rgb: var(--ion-color-danger-rgb);
  --ion-color-contrast: var(--ion-color-danger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
  --ion-color-shade: var(--ion-color-danger-shade);
  --ion-color-tint: var(--ion-color-danger-tint);
}

/* Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

/* ion reset */

.list-ios {
  margin-bottom: 0;
}

ion-modal ion-page,
ion-modal .ion-page {
  min-width: 100px;
}

/* end ion reset */

h1 {
  display: block;
  color: lightgray;
  position: relative;
  text-decoration: underline;
  -webkit-text-decoration-color: red;
          text-decoration-color: red;
}

h1::after {
  display: block;
  content: "DON'T USE H1 TAG ! ! !";
  color: red;
}

h2,
h3,
h4,
h5,
h6 {
  display: block;
  margin: 0;
}

p,
div,
li,
ion-item,
.strong-text,
.normal-text {
  font-size: var(--ion-font-size);
}

p,
div,
li,
.strong-text,
.normal-text {
  color: var(--okx-normal-text-color);
}

.strong-text {
  font-weight: bold;
}

.normal-text {
  font-weight: normal;
}

.small-text {
  font-size: var(--okx-small-text-font-size);
  color: inherit;
  font-weight: 500;
}

.light-text {
  color: var(--okx-light-text-color);
}

/* ion-input, input {
	direction: ltr !important;
	unicode-bidi: bidi-override !important;
} */

ion-input,
ion-select,
ion-datetime,
.sc-ion-input-md-h,
.sc-ion-input-ios-h,
.datetime-text,
.masked-input {
  --color: var(--ion-color-dark);
  color: var(--ion-color-dark);
}

ion-datetime.datetime-placeholder {
  opacity: 0.33;
}

.big-label {
  font-size: var(--okx-big-label-font-size);
  font-weight: bold;
  color: var(--okx-big-label-color);
  padding: var(--okx-big-label-padding);
  margin: var(--okx-big-label-margin);
}

.title {
  position: relative;
  font-family: var(--ion-default-font-bold);
  font-size: var(--okx-title-font-size);
  font-weight: bold;
  color: var(--okx-title-color);
  padding: var(--okx-title-padding);
  margin: var(--okx-title-margin);
  z-index: 1;
}

.subtitle,
h2,
h3,
h4,
h5,
h6 {
  font-size: var(--okx-subtitle-font-size);
  font-weight: bold;
  color: var(--okx-subtitle-color);
  padding: var(--okx-subtitle-padding);
  margin: var(--okx-subtitle-margin);
}

ion-label h2,
ion-label h3,
ion-label h4,
ion-label h5,
ion-label h6 {
  font-size: var(--okx-sectiontitle-font-size) !important;
}

.sectiontitle {
  font-size: var(--okx-sectiontitle-font-size);
  font-weight: bold;
  color: var(--okx-sectiontitle-color);
  padding: var(--okx-sectiontitle-padding);
  margin: var(--okx-sectiontitle-margin);
}

.title2 {
  color: var(--ion-color-title2);
  margin: 40px 0 20px;
}

.padded {
  padding: var(--ion-padding);
}

.no-margin {
  margin: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-margin-top {
  margin-top: 0;
}

.no-padding-top {
  padding-top: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.no-padding {
  padding: 0;
}

ion-grid.no-padding {
  --ion-grid-padding: 0;
}

ion-grid.no-padding ion-col {
  padding: 0;
}

.md {
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: #fff;
  --ion-toolbar-color-activated: #fff;
}

.box-holder {
  background-color: var(--ion-color-box-holder);
  border-radius: 5px;
  padding: 0px 15px;
  border: 1px solid var(--ion-color-light);
}

.box-holder.box-holder--feedback {
  border: 1px solid rgba(var(--ion-color-gray-rgb), 0.7);
}

.box-holder.box-holder--register {
  padding: 0px;
  border: 1px solid rgba(var(--ion-color-primary-rgb), 1);
}

.route-register .toggle {
  padding: 15px 0 15px 15px;
  flex-direction: unset;
}

.pad10 {
  padding: 10px;
}

.pad20 {
  padding: 20px;
}

.pad40b {
  padding-bottom: 40px;
}

.pad5l {
  padding-left: 5px;
}

.pad17l {
  padding-left: 17px;
}

.box-holder ion-item {
  --padding-start: 0px;
  --border-color: var(--ion-separator-color);
}

.box-holder.box-holder--feedback ion-item {
  --border-color: 1px solid rgba(var(--ion-color-gray-rgb), 0.7);
}

.single-item {
  margin-bottom: 10px;
}

ion-label h2 {
  color: var(--ion-color-form-label-dark);
  font-weight: 500;
}

ion-note {
  color: var(--ion-color-note);
  font-size: var(--okx-note-font-size);
}

ion-label:not(.contact-number-label).ion-text-wrap {
  line-height: 1 !important;
}

ion-item {
  --padding-start: 0;
  --inner-padding-end: 0;
}

ion-item ion-button {
  margin-bottom: 0;
  height: auto;
}

ion-button {
  --background-activated: var(--ion-color-light);
}

ion-button.rounded {
  --border-radius: 60px;
}

.float-input-holder {
  --padding-start: 0px;
  --border-color: var(--ion-color-form-label);
}

.top-small {
  margin-top: var(--ion-top-small);
}

.top-medium {
  margin-top: var(--ion-top-medium);
}

.verified-content {
  width: 190px;
  margin: 0 auto;
  text-align: center;
}

ion-button {
  --border-radius: var(--okx-box-radius);
  --box-shadow: none;
  font-size: var(--ion-font-size);
  margin: 5px 0 0;
  text-transform: none;
  letter-spacing: normal;
}

ion-button.validation {
  --background: var(--okx-color-white);
  --color: var(--ion-color-primary);
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-secondary);
}

ion-button.ion-color-primary {
  --border-style: solid;
  --border-width: 2px;
  --border-color: var(--ion-color-primary);
}

ion-button.ion-color-secondary {
  height: 39px !important;
  --ion-color-contrast: #fff !important;
  --background-activated: var(--ion-color-light);
  font-family: var(--ion-font-family);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px !important;
  letter-spacing: 0.2px;
}

ion-button.ion-color-secondary.button-disabled {
  --ion-color-base: rgba(var(--ion-color-gray-rgb), 0.5) !important;
  --color: rgba(var(--ion-color-gray), 1);
}

ion-button.ion-color-white {
  --border-style: none;
  --border-width: 0;
  --border-color: transparent;
}

ion-button.cancel {
  height: 44px;
  --background: var(--okx-cancel-button-color) !important;
  --color: var(--ion-color-primary);
}

ion-button.cancel.button-fill {
  --background: rgba(var(--ion-color-gray-rgb), 0.2) !important;
}

ion-button.button-outline {
  height: 39px;
  --background-activated: var(--ion-color-light);
  font-family: var(--ion-default-font-bold);
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.3px;
}

ion-button.link {
  --background: var(--okx-cancel-button-color) !important;
  --color: inherit;
  font-weight: 500;
  text-transform: none;
  text-transform: initial;
  cursor: pointer;
  font-size: 9px !important;
}

ion-button.link.underlined.ion-color-secondary {
  font-family: var(--ion-font-family);
  font-size: var(--okx-small-text-font-size);
  font-weight: normal;
}

ion-button[type='submit'] {
  height: 44px;
  --background: var(--ion-color-secondary);
  /* --color: var(--ion-color-contrast-secondary); */
  text-transform: uppercase;
}

ion-header ion-title {
  text-transform: uppercase;
  font-weight: bold;
}

.pov,
.poo,
.relative {
  position: relative;
}

.mixed-right,
.mixed-right * {
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  align-self: center;
  justify-self: flex-end;
}

.mixed-left,
.mixed-left * {
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  justify-self: flex-start;
}

.mixed-left,
.mixed-left * .grow {
  flex-grow: 0.5;
}

.contrast-separator {
  display: block;
  height: 0;
  line-height: 0;
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin: 16px 0;
}

.contrast-separator.contrast-separator-mini {
  margin: 12px 0;
}

.poster {
  padding-top: 65%;
  background-color: transparent;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.no-padding {
  padding: 0;
}

.text-link {
  --ion-toolbar-background: transparent;
  --background-hover: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
  --color: var(--ion-color-gray);
  --color-hover: var(--ion-color-dark);
  --color-activated: var(--ion-color-dark);
  --color-focused: var(--ion-color-dark);
  --ripple-color: #fff;
  text-transform: unset;
  font-size: inherit;
}

span.link {
  color: inherit;
  cursor: pointer;
  font-weight: 500;
}

span.link.link-primary {
  color: var(--ion-color-primary);
  cursor: pointer;
}

.paddLR {
  padding-right: 0px;
  padding-left: 0px;
}

ion-button.link,
ion-buttons ion-button.link,
.sc-ion-buttons-md-h.ion-color.sc-ion-buttons-md-s .button.link,
.ion-color .sc-ion-buttons-md-h.sc-ion-buttons-md-s .button.link,
.sc-ion-buttons-ios-h.ion-color.sc-ion-buttons-ios-s .button.link,
.ion-color .sc-ion-buttons-ios-h.sc-ion-buttons-ios-s .button.link {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --background: transparent;
  --background-active: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --border-color: transparent;
  --border-radius: 0;
  --border-style: none;
  --border-width: 0;
  --box-shadow: none;
  --ripple-color: transparent;
  --color: var(--ion-color-primary);
  --color-hover: var(--ion-color-primary-shade);
  --color-focused: var(--ion-color-primary-shade);
  --color-activated: var(--ion-color-primary-shade);
  font-size: inherit;
}

ion-button.customized-button {
  height: 44px;
  --background-activated: var(--ion-color-secondary);
  --color: var(--ion-color-primary);
  font-family: var(--ion-default-font-bold);
  font-size: 18px;
  --border-radius: 20px;
}

.segment-holder {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.segment-holder ion-segment {
  flex: 0 0 auto;
}

.segment-holder .segment-content {
  flex: 1 1 auto;
  overflow: hidden;
  overflow-y: auto;
}

.scrollable {
  overflow: auto;
}

.scrollable-y {
  overflow: hidden;
  overflow-y: auto;
}

.scrollable-x {
  overflow: hidden;
  overflow-x: auto;
}

.bordered,
.bordered-top,
.bordered-bottom,
.bordered-left,
.bordered-right {
  border: 1px solid var(--okx-border-color);
}

.bordered-primary {
  border: 1px solid var(--ion-color-primary);
  /* border: 1px solid var(--ion-color-secondary); */
}

.bordered-top {
  border-width: 1px 0 0;
}

.bordered-bottom {
  border-width: 0 0 1px;
}

.bordered-left {
  border-width: 0 0 0 1px;
}

.bordered-right {
  border-width: 0 1px 0 0;
}

.underlined {
  text-decoration: underline;
  cursor: pointer;
}

ion-segment-button {
  height: 50px;
  border-radius: 0px;
  color: var(--ion-color-dark);
  border: 0 none;
  border-bottom: 4px solid var(--ion-color-primary-contrast);
  background-color: var(--ion-color-primary-contrast);
  font-weight: bold;
}

ion-segment-button.segment-button-checked {
  border-bottom-color: var(--ion-color-secondary);
  color: var(--ion-color-primary);
}

.clickable {
  cursor: pointer;
}

.field-error {
  color: var(--ion-color-danger);
  font-size: var(--okx-error-font-size);
  margin: var(--okx-error-margin);
}

ion-checkbox {
  --ion-color-secondary-contrast: #ffffff;
  --border-radius: 50%;
}

.absolute-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.flex-col-wrapper,
.flex-col-holder,
.flex-row-wrapper,
.flex-row-holder {
  display: flex;
}

.flex-col-wrapper,
.flex-col-holder {
  flex-direction: row;
}

.flex-col-wrapper.flex-reverse,
.flex-col-holder.flex-reverse {
  flex-direction: row-reverse;
}

.flex-row-wrapper,
.flex-row-holder {
  flex-direction: column;
}

.flex-row-wrapper.flex-reverse,
.flex-row-holder.flex-reverse {
  flex-direction: column-reverse;
}

.flex-col-wrapper > div,
.flex-col-holder > div,
.flex-row-wrapper > div,
.flex-row-holder > div {
  flex: 1 1 auto;
}

.flex-col-wrapper > div.flex-min,
.flex-col-holder > div.flex-min,
.flex-row-wrapper > div.flex-min,
.flex-row-holder > div.flex-min {
  flex: 0 1 var(--okx-flex-min);
}

.flex-col-wrapper > div.flex-optional,
.flex-col-holder > div.flex-optional,
.flex-row-wrapper > div.flex-optional,
.flex-row-holder > div.flex-optional {
  flex: 1 1;
}

.flex-col-wrapper > div.flex-spacer,
.flex-col-holder > div.flex-spacer,
.flex-row-wrapper > div.flex-spacer,
.flex-row-holder > div.flex-spacer {
  flex: 0 1 var(--okx-flex-spacer);
}

.flex-centered {
  align-items: center;
}

.flex-stretched {
  align-items: stretch;
}

.two-column-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.two-column-list.between > *:nth-of-type(even) {
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  justify-self: flex-end;
  place-items: end;
}

.two-column-list.between > *:nth-of-type(odd) {
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  justify-self: flex-start;
  place-items: start;
}

.two-column-list.center > * {
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  place-items: center;
}

.auto-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.absolute-content {
  position: absolute;
  left: 0;
  top: -1px;
  right: 0;
  bottom: 0;
  -webkit-padding-start: 16px;
          padding-inline-start: 16px;
  -webkit-padding-start: var(--ion-padding, 16px);
          padding-inline-start: var(--ion-padding, 16px);
  -webkit-padding-end: 16px;
          padding-inline-end: 16px;
  -webkit-padding-end: var(--ion-padding, 16px);
          padding-inline-end: var(--ion-padding, 16px);
  padding-left: 16px;
  padding-left: var(--ion-padding, 16px);
  padding-right: 16px;
  padding-right: var(--ion-padding, 16px);
  padding-top: 16px;
  padding-top: var(--ion-padding, 16px);
  padding-bottom: 16px;
  padding-bottom: var(--ion-padding, 16px);
}

.web .absolute-content {
  top: 0;
}

.absolute-content > div {
  position: relative;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 1;
}

.cursor {
  cursor: pointer;
}

.overflow-visible {
  overflow: visible;
}

.centered,
.centered * {
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
}

.righted,
.righted * {
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  justify-self: flex-end;
}

.righted,
.righted * .grow {
  flex-grow: 0;
}

.righted,
.righted * .instruction-grow {
  flex-grow: 1;
}

.lefted,
.lefted * {
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  justify-self: flex-start;
}

.self-center {
  align-self: center;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.bold {
  font-weight: bold;
}

.inline-block {
  display: inline-block;
}

.hidden,
.web-only {
  display: none;
}

.mobile-only {
  display: block;
}

.invisible {
  visibility: hidden;
}

.mr-10 {
  margin-right: 10px;
}

.mr-5 {
  margin-right: 5px;
}

.very-small-text {
  font-size: 9px;
  letter-spacing: 0.013rem;
}

hr {
  color: transparent;
  background-color: transparent;
  height: 0;
  min-height: 0;
  line-height: 0;
  margin: var(--okx-hr-margin);
  border: 0 none;
  border-top: 2px solid var(--ion-color-gray);
}

ion-badge.small {
  width: 15px;
  height: 15px;
  line-height: 13px;
  font-size: 7px;
  --padding-start: 2px;
  --padding-end: 2px;
  --padding-top: 2px;
  --padding-bottom: 2px;
  color: var(--ion-color-white) !important;
  border-radius: 50%;
  min-width: 11px;
  text-transform: none;
  margin-left: 5px;
  position: absolute;
}

.card-thumb {
  display: inline-block;
  width: 55px;
  height: 35px;
  /*background-color: var(--ion-color-light);*/
  position: relative;
  border-radius: 3px;
  border: 1px solid var(--ion-color-light);
}

.card-thumb.visa::after {
  display: block;
  position: absolute;
  left: 0;
  top: 10px;
  right: 0;
  bottom: 0;
  content: 'VISA';
  color: #2566af;
  text-align: center;
  font-weight: 900;
  font-style: italic;
}

.card-thumb.mc::before {
  display: block;
  position: absolute;
  left: 5px;
  top: 5px;
  width: 26px;
  height: 26px;
  content: '';
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  background-color: #c00;
}

.card-thumb.mc::after {
  display: block;
  position: absolute;
  left: 0;
  top: 2px;
  right: 0;
  bottom: 0;
  content: 'MasterCard';
  color: #fff;
  font-size: 8px;
  padding-top: 11px;
  text-align: center;
  font-weight: bold;
  font-style: italic;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
  /*background-image: radial-gradient(circle at 36px 16px, #f90 0px 12px,transparent 13px);*/
  background-image: radial-gradient(
    circle at 36px 16px,
    rgba(255, 153, 0, 0.7) 0px 12px,
    transparent 13px
  );
}

ion-input:-webkit-autofill,
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #999;
}

ion-input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: transprent, 0 0 0 50px white inset;
  -webkit-text-fill-color: #999;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

/* Specific style for heading / text immidietely underneath title of the screen */
.heading {
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 0.021em;
  opacity: 0.8;
}

/* Specific style, text seems like input-label & input are inline */
.inline-input {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.inline-input--bordered {
  border-bottom: 1px solid var(--ion-color-light);
}

.blocked-input {
  width: 100%;
  display: block;
}

.blocked-input--bordered {
  border-bottom: 1px solid rgba(var(--ion-color-light-rgb), 1);
}

.inline-input > ion-text,
.inline-input > label {
  align-self: center;
  flex: 0 0 40%;
}

.route-vouchers .inline-input > ion-text,
.route-vouchers .inline-input > label {
  flex: unset;
}

.inline-input.inline-input--validate {
  justify-content: initial;
  border-bottom: 0;
}

.inline-input.inline-input--validate .data-picker-input {
  width: 100%;
}

.inline-input > ion-text > span,
.blocked-input > ion-text > span {
  font-family: var(--ion-default-font-bold);
  font-weight: bold;
  letter-spacing: 0.044em;
  color: var(--ion-color-primary);
  font-size: 12px;
}

.inline-input > ion-input,
.blocked-input > ion-input {
  --placeholder-color: var(--ion-color-primary);
  --placeholder-opacity: 1;
  text-transform: none;
  text-transform: initial;
  /* margin-inline-start: 20px; */
}

.blocked-input > ion-input {
  -webkit-margin-start: 0;
          margin-inline-start: 0;
}

.inline-input.inline-input-gaped > ion-input {
  -webkit-margin-start: 50px;
          margin-inline-start: 50px;
}

.h-12 {
  font-size: 12px !important;
  font-weight: bold !important;
}

.t-9 {
  font-size: 9px !important;
}
.mbsc-material .mbsc-fr-w,
.mbsc-mobiscroll .mbsc-fr-w {
  background: var(--ion-color-contrast);
}

.mbsc-sc-whl-c {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.mbsc-fr-popup {
  background-color: rgb(255, 255, 255);
}

.ion-page .static ion-toolbar:first-of-type {
  padding-top: 0px;
}

.web * {
  scrollbar-width: thin !important;
  scrollbar-color: var(--okx-scrollbar-color);
}

.web ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.web ::-webkit-scrollbar-track {
  background: var(--okx-scrollbar-track-color);
  border-radius: 0;
}

.web ::-webkit-scrollbar-thumb {
  background: var(--okx-scrollbar-handle-color);
  border-radius: 0;
}

.web  .ion-page > ion-content  {
  background-image: url(/static/media/refer-friend.eaf6079f.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.web .web-only {
  display: block;
}

.web .mobile-only {
  display: none;
}

/* ion-app.ion-page.web {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

ion-app.ion-page.web.route-dashboard,
ion-app.ion-page.web.route-loyalty,
ion-app.ion-page.web.route-order-completed,
ion-app.ion-page.web.route-social {
	background-image: url(../assets/images/new-dashboard-01.jpg);
}


ion-app.ion-page.web.route-click-and-collect {
	background-image: url(../assets/images/clickAndCollect.jpg);
}


ion-app.ion-page.web.route-history-details,
ion-app.ion-page.web.route-history,
ion-app.ion-page.web.route-login,
ion-app.ion-page.web.route-register, 
ion-app.ion-page.web.route-reset-password {
	background-image: url(../assets/images/history-bg-web.jpg);
} */

.web .static ion-toolbar.primary-toolbar {
  --background: transparent none;
}

.web .mbsc-select-input {
  cursor: pointer;
}

.web .mbsc-select-input[disabled],
.web .mbsc-sel-hdn[disabled] + .mbsc-select-input {
  cursor: default;
}

.web .static.route-register + .dynamic-header {
  display: none;
}

.web
  #main
  > ion-content:not(.route-order):not(.route-dashboard):not(.route-delivery-options):not(.route-delivery-address-check):not(.route-locations):not(.route-refer-a-friend):not(.route-pick-up-point-check):not(.route-history):not(.route-click-and-collect):not(.route-delivery-options):not(.route-delivery-address-check):not(.route-locations):not(.route-refer-a-friend):not(.route-pick-up-point-check):not(.route-order-to-table)
  > .no-padding,
.web #main > ion-content > .ion-padding {
  position: fixed;
  left: 50%;
  top: var(--okx-bsmodal-top);
  bottom: 80px;
  width: var(--okx-bsmodal-width-lg);
  min-height: 240px;
  margin-left: calc(var(--okx-bsmodal-width-lg) / -2);
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  overflow-y: hidden;
}
/* 
.web .restaurant-card {
	top: 50%;
    bottom: auto;
    left: 50%;
    right: auto;
    position: fixed;
    transform: translate(-50%,-50%);
    width: 50%;
    max-width: var(--okx-bsmodal-width-lg);
    min-width: 280px;
    padding: 220px 64px 64px;
    margin-left: auto;
    margin-right: auto;
    border-radius: var(--okx-box-radius);
} */

/* .web #main > ion-content:not(.route-order) > .no-padding > .absolute-content > .flex-min:last-of-type > ion-button, */
/* .web #main > ion-content:not(.route-order) > .ion-padding > .absolute-content > .flex-min:last-of-type > ion-button, */
/* .web #main > ion-content:not(.route-order-summary) > .no-padding > .absolute-content > .flex-min:last-of-type > ion-button, */
/* .web #main > ion-content:not(.route-order-summary) > .ion-padding > .absolute-content > .flex-min:last-of-type > ion-button, */
.web .restaurant-card ion-card-content ion-button:last-of-type {
  margin: 0;
}

/*
.web #main > ion-header,
.web #main > ion-content {
	-webkit-animation: fadein 1s;
	-moz-animation: fadein 1s;
	-ms-animation: fadein 1s;
	-o-animation: fadein 1s;
	animation: fadein 1s;
}

@keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@-moz-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@-webkit-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@-ms-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@-o-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
*/

/* responsive */

@media screen and (max-width: 1200px) {
  .web
    #main
    > ion-content:not(.route-order):not(.route-dashboard):not(.route-delivery-options):not(.route-delivery-address-check):not(.route-locations):not(.route-refer-a-friend):not(.route-pick-up-point-check):not(.route-history)
    > .no-padding,
  .web #main > ion-content > .ion-padding {
    width: var(--okx-bsmodal-width-md);
    margin-left: calc(var(--okx-bsmodal-width-md) / -2);
  }
  .web .restaurant-card {
    width: var(--okx-bsmodal-width-md);
  }
}

@media screen and (max-width: 960px) {
  .web
    #main
    > ion-content:not(.route-order):not(.route-dashboard):not(.route-delivery-options):not(.route-delivery-address-check):not(.route-locations):not(.route-refer-a-friend):not(.route-pick-up-point-check):not(.route-history)
    > .no-padding,
  .web #main > ion-content > .ion-padding {
    width: var(--okx-bsmodal-width-sm);
    margin-left: calc(var(--okx-bsmodal-width-sm) / -2);
  }
  .web .restaurant-card {
    width: var(--okx-bsmodal-width-sm);
  }
}

@media screen and (max-width: 991px) {
  .web .collapse-drawer-icon {
    display: block;
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    cursor: pointer;
    font-size: 25px;
  }
}

/* responsive end */

/* big screen override */

@media screen and (min-width: 992px) {
  .web ion-header:not(.route-login) + ion-toolbar.dynamic-header,
  .web ion-header:not(.route-login) + .content-spinner + ion-toolbar.dynamic-header {
    top: 16px;
    width: 100px;
  }
}

.header-md::after {
  background-image: none;
}

ion-toolbar.dynamic-header {
  --min-height: var(--ion-toolbar-min-height);
  --ion-toolbar-background: transparent;
  --ion-toolbar-color: var(--ion-color-dark);
  /* margin-top: -15px; */
}

.web ion-toolbar.dynamic-header {
  margin-top: 0;
  position: absolute;
  top: 80px;
  z-index: 6;
  pointer-events: none;
}

.web ion-toolbar.dynamic-header ion-buttons {
  pointer-events: all;
}

#main > ion-content {
  z-index: 5;
}

ion-content.ion-color-transparent {
  --background: transparent;
}

.ion-page {
  min-width: 285px;
}

.promo-bar,
.promo-bar-scrim {
  position: absolute;
  position: fixed;
  z-index: 5;
}

/* .promo-bar-scrim {
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.000000001);
} */

.promo-bar {
  left: 10%;
  right: 10%;
  top: 20%;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  background: var(--ion-color-secondary) url(/static/media/info-white.d47b8957.svg) no-repeat 5px 50%;
  background-size: 30px 30px;
}

.promo-bar.warning {
  background-image: url(/static/media/warning-white.07991784.svg);
}

.promo-bar.error {
  background-image: url(/static/media/error-white.25f0fcdb.svg);
}

.promo-bar-type {
  font-weight: bold;
  text-transform: uppercase;
}

.promo-bar-type,
.promo-bar-body {
  color: var(--ion-color-primary-contrast);
}

.web .promo-bar {
  max-width: var(--okx-bsmodal-width-lg);
  margin-left: auto;
  margin-right: auto;
  top: 2%;
}

@media screen and (min-height: 615px) and (max-height: 722px) {
  .web .promo-bar {
    top: 7%;
  }
}

@media screen and (min-height: 722px) {
  .web .promo-bar {
    top: 15%;
  }
}

ion-content div.dash-layout {
  background-image: url(/static/media/dashboard.7739966e.png);
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: cover;
  z-index: -1;
}

ion-content div.dash-layout::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 60%;
  z-index: 0;
  background-image: linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 60%);
}

ion-content div.dash-layout::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  z-index: 0;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.dash-card {
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 0;
  z-index: 1;
  --background: #ffffff;
  border-radius: var(--ion-card-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  margin-top: 0;
  margin-bottom: 0;
  max-height: 410px;
}

.dash-image {
  display: flex;
  justify-content: center;
  height: 160px;
}
.dash-image img {
  object-fit: contain;
}
.dash-menu {
  padding: 0 50px;
}

.dash-menu .list-md {
  background: transparent;
}

.dash-menu ion-item {
  --color: var(--ion-color-secondary);
  --background: trasparent;
  --background-hover: trasparent;
  --min-height: 32px;
  --border-color: var(
    --okx-color-border-dash
  ); /* This firstly variable of border-color replaced with border-color right below */
  --border-color: rgba(var(--ion-color-gray-rgb), 0.3);
}

.dash-card ion-card-title,
.dash-menu ion-item {
  font-weight: bold;
  white-space: pre-wrap;
}

.dash-card ion-card-title {
  font-size: 20px;
  letter-spacing: 0.033em;
}

.dash-card ion-card-title > span {
  font-size: var(--ion-font-size);
  font-weight: 500;
  text-transform: none;
  text-transform: initial;
  letter-spacing: normal;
}

.dash-menu ion-label {
  text-align: center;
  font-weight: bold;
}

.dash-menu .item ion-label.sc-ion-label-ios-h,
.dash-menu .item ion-label.sc-ion-label-md-h {
  font-weight: bold;
  letter-spacing: 0.033em;
}

.web .static.route-dashboard {
  background: transparent none;
}

.web ion-content.route-dashboard {
  --background: transparent none;
  /* --ion-color-white: transparent; */
}

.web #main > ion-content.route-dashboard div.dash-layout.absolute-content {
  background: transparent none;
}

.web #main > ion-content div.dash-layout::before {
  background-image: none;
}

.web .dash-card {
  position: absolute;
  top: 50%;
  bottom: auto;
  left: 50%;
  right: auto;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 50%;
  max-width: var(--okx-bsmodal-width-md);
  min-width: 280px;
  padding-top: 24px;
  padding-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--okx-box-radius);
  background-position: 50% 108%;
  background-size: 120px auto;
}

.web .dash-menu ion-item {
  --color: var(--ion-color-secondary);
  --background: var(--ion-color-secondary);
  margin-bottom: 20px;
  border-radius: var(--okx-small-radius);
}

.reorder-button {
  background-color: var(--ion-color-secondary);
  float: right;
  border-radius: 25px 0px 0px 25px;
  width: 25%;
  height: 25px;
  color: var(--ion-color-white);
  z-index: 20;
}

.validate-button-wrapper {
  height: 45px;
}

.validate-button-wrapper ion-row {
  width: 165px;
  margin: 0 auto;
  border-radius: 20px;
  border: 1px solid var(--ion-color-gray);
  margin-bottom: 25px;
  color: var(--ion-color-gray);
}

.validate-button-wrapper.validate-button-wrapper--gridy ion-row {
  display: grid;
  grid-template-columns: 30px 1fr;
  padding: 0 5px;
}

.validate-button-wrapper ion-row ion-col div ion-icon {
  font-size: 19px;
  color: var(--ion-color-gray);
}

.validate-button-wrapper ion-row ion-col.verified-right {
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  justify-self: flex-start;
  flex-grow: 3;
}

ion-modal.modal-classic {
  --width: 70%;
  --height: 50%;
  --border-radius: 10px;
}

.web ion-modal.modal-classic.quantity-modal {
  --height: 20%;
  --min-height: 210px;
}

.web ion-modal.modal-classic.quantity-modal .modal-classic-content {
  justify-content: center;
}

ion-modal.modal-classic .modal-classic-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 5px 13px;
  max-height: 100%;
}

ion-modal.modal-classic .modal-classic-content {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 12px;
}

ion-modal.modal-classic:not(.allergen-modal):not(.apply-points-modal)
  .modal-classic-content
  .absolute-content {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  padding-left: 0;
  padding-right: 0;
}

ion-modal.modal-classic .modal-classic-closer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 13px;
  cursor: pointer;
  z-index: 1;
}

ion-modal.modal-classic .modal-classic-header,
ion-modal.modal-classic .modal-classic-action {
  flex: 0 1 auto;
}

ion-modal.modal-classic .modal-classic-header {
  padding: 15px 5px;
}

ion-modal.modal-classic .modal-classic-header h3 {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

/* Unlock rewards style */

:root {
  --reward-red: #6e2b13;
  --reward-black: #000;
}

.reward {
  position: relative;
  margin-bottom: 20px;
}

.reward.green .reward-content {
  background-color: var(--ion-color-primary);
}

.reward.red .reward-content {
  background-color: #6e2b13;
  background-color: var(--reward-red);
}

.reward.black .reward-content {
  background-color: #000;
  background-color: var(--reward-black);
}

.reward-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reward-cover {
  position: absolute;
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}

.reward-cover ion-icon {
  position: absolute;
  fill: #fff;
  font-size: 42px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.reward-content::before,
.reward-content::after {
  display: block;
  content: '';
  position: absolute;
  top: 8px;
  bottom: 0;
  width: 20px;
  background: radial-gradient(#fff 0px, #fff 6px, transparent 7px, transparent);
  background-size: 20px 20px;
}

.reward-content::before {
  left: -9px;
}

.reward-content::after {
  right: -9px;
}

.reward-content div {
  color: #fff;
}

.reward-spacer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 20px;
  min-width: 20px;
}

.reward-info,
.reward-stamp {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  text-align: center;
}

.reward-stamp {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 85px;
  min-height: 112px;
  min-width: 85px;
  position: relative;
}

.reward-stamp::after {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 70px;
  height: 70px;
  margin: -38px 0 0 -38px;
  border-radius: 50%;
  border: 3px #fff solid;
  background: transparent url(/static/media/social.e6f5af8e.svg) no-repeat 50% 50%;
  background-size: 40px 40px;
}

.reward:not(.default-font) .reward-title,
.reward:not(.default-font) .reward-subtitle {
  font-family: 'Roboto';
}

.reward-title,
.reward-subtitle {
  text-transform: uppercase;
}

.reward-title {
  font-size: 32px;
  font-weight: bold;
}

.reward-subtitle {
  font-size: 12px;
}

.reward-description {
  font-size: 8px;
}

.reward.default-font .reward-title {
  line-height: 28px;
  font-weight: normal;
}

.reward.default-font .reward-subtitle {
  font-weight: bold;
}

.reward-bar {
	height: 150px;
	display: flex;
	flex-direction: column;
	border-bottom-left-radius: 1px;
	border-bottom-right-radius: 1px;
	box-shadow: 0 2px 5px rgba(0,0,0,0.15);
	background: rgba(243, 243, 243, .2);
	padding: 20px;
	margin-top: -4px;
	overflow: hidden;
}

.reward-bar > div:first-child, .reward-bar > div:last-child {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
}

.reward-bar > div:first-child {
	padding-left: 16px;
}

.reward-bar ion-button.ion-color-secondary {
	width: 100%;
	margin-bottom: 0;
	--border-width: 1px;
	--border-color: rgba(var(--ion-color-gray-rgb), .5);
	--border-radius: 3px;
	color: var(--ion-color-secondary);
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
}

.reward-bar p {
	color: var(--ion-color-tertiary);
	font-family: var(--ion-default-font-second-bold);
	font-size: 21px;
	text-align: center;
	text-transform: capitalize;
	margin: 0;
}
ion-modal.modal-classic.reward-modal {
  --width: 85%;
  --height: 70%;
  --border-radius: 10px;
}

ion-modal.modal-classic.reward-modal .modal-classic-wrapper {
  padding: 0;
}

ion-modal.modal-classic.reward-modal .modal-classic-header {
  background-color: var(--ion-color-secondary);
  padding-left: 20px;
}

ion-modal.modal-classic.reward-modal .modal-classic-header h3 {
  color: var(--ion-color-white);
  font-family: var(--ion-default-font-bold);
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

ion-modal.modal-classic.reward-modal .modal-classic-closer {
  color: var(--ion-color-white);
  font-size: 18px;
}

ion-modal.modal-classic.reward-modal .modal-classic-content {
  padding: 30px 15px;
}

.reward-modal-content {
  padding: 0 30px;
}

.modal-classic-content > div > img,
.reward img {
  object-fit: cover;
  object-position: center;
}

ion-content.route-loyalty > .ion-padding .absolute-content {
  background: #fff;
}

ion-content.route-loyalty > .ion-padding .absolute-content .big-number {
  font-family: var(--ion-default-font-bold);
  font-size: 5em;
  color: var(--ion-color-secondary);
}

#main
  > ion-content.route-rewards
  > .ion-padding
  .absolute-content
  .reward-modal-content
  > ion-text
  > span {
  font-size: 23px;
}

#main > ion-content.route-rewards > .ion-padding .absolute-content ion-item {
  --border-color: var(--ion-color-light);
}

#main > ion-content.route-rewards > .ion-padding .absolute-content .inline-input > ion-text {
  flex: 0 0 25%;
}

.reward-modal-qr {
  /* background: transparent url(../../assets/images/qr-dummy.png) no-repeat 50% 0; */
  background-size: contain;
  width: 100%;
  height: 200px;
  border: 10px solid var(--ion-color-white);
}

.reward-item ion-item ion-input {
  --placeholder-color: var(--ion-color-primary);
  --placeholder-opacity: 1;
  text-transform: none;
  text-transform: initial;
  -webkit-margin-start: 20px;
          margin-inline-start: 20px;
}

.web .static.route-rewards {
  background: transparent none;
}

.web ion-content.route-rewards {
  --background: transparent none;
  --ion-color-white: transparent;
}

.web #main > ion-content.route-rewards > .ion-padding .absolute-content {
  border-radius: inherit;
  overflow: hidden;
  padding: 0;
}

.web #main > ion-content.route-rewards > .ion-padding .absolute-content .flex-min:first-of-type {
  padding: var(--ion-padding) var(--ion-padding) 0 var(--ion-padding);
}

.web #main > ion-content.route-rewards > .ion-padding .absolute-content > .scrollable-y {
  padding: 0 var(--ion-padding);
}

.web .clickup {
  position: absolute;
}

.loyalty-content {
  --ion-color-primary: var(--ion-color-secondary) !important;
  --okx-normal-text-color: var(--ion-color-secondary) !important;
  display: grid;
}

.loyalty-content.scan {
  padding-bottom: 60px;
  height: 100%;
  padding-top: 30px;
}

.loyalty-content.scan h2 {
  margin-bottom: 5px;
  letter-spacing: 0.044em;
  font-size: 20px !important;
}

.loyalty-title-wrap {
  text-align: left;
  padding: 0 30px 20px 30px;
  color: black;
}

.scan-heading {
  font-family: var(--ion-default-font-bold) !important;
  font-weight: normal !important;
}

.stamps-info {
  font-size: 12px !important;
  font-family: var(--ion-default-font-bold);
  color: black !important;
  font-weight: 100 !important;
  padding-bottom: 10px;
}

.last-stamp-text {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
}

.loyalty-content {
  background: var(--ion-color-background) !important;
  padding-top: 45px !important;
}

.stamps-text {
  padding-top: 20px;
  text-align: center;
  font-family: var(--ion-default-font);
}

.noQrCode {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.qr-holder {
  margin-top: 20px;
}

.qr-holder canvas {
  border: 20px solid #fff;
  border-radius: 10px;
}

/* end second tab */

.points-tab {
  /* background: transparent url(../../assets/images/history-details.jpg) no-repeat; */
  background-position: 50% 100%;
  background-size: 100% auto;
  background: var(--ion-color-background);
  z-index: 1;
}

.points-tab::before {
  background: url(/static/media/dashboard.7739966e.png);
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  content: '';
}
.points-tab::after {
  background: linear-gradient(#fff 30%, rgba(255, 255, 255, 0) 130%);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  content: '';
}
#main > ion-content.route-loyalty .absolute-content.points-tab img {
  width: 50px;
  -webkit-filter: invert(1);
          filter: invert(1);
}


.verified-content {
  width: 190px;
  margin: 0 auto;
}

.points-tab .normal-text {
  font-weight: bold;
}

#main > ion-content.route-loyalty > .no-padding .loyalty-title-wrap > ion-text:last-child > span {
  font-weight: bold;
}


#main > ion-content.route-loyalty > .no-padding .absolute-content.points-tab .points {
  font-size: 4em;
}

.web #main > ion-content.route-loyalty > .no-padding {
  position: absolute;
  left: 0 !important;
  right: 0!important;
  bottom: 0!important;
  top: 0!important;
  height: 70%!important;
  margin: auto!important;
}

.web .route-loyalty .tabs {
  position: relative;
  height: 100%;
  border-radius: inherit;
}

.web .route-loyalty .tabs ion-slides,
ion-slide {
  height: calc(100vh - 350px);
  min-height: 350px;
}

.web ion-content.route-loyalty {
  --background: transparent;
  --ion-color-white: transparent;
  background-color: transparent;
}

.web ion-segment-button.segment-button-checked {
  border-bottom-color: var(--ion-color-secondary);
}

.web .points-tab {
  background: transparent none;
}

.stamps {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, minmax(85px, 1fr));
  border: 2px solid var(--ion-color-secondary);
  border-radius: 1em;
  padding: 10px;
}

.verified-content {
  width: 190px;
  margin: 0 auto;
}

.stamp-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px dashed var(--ion-color-light);
  border-right: 1px dashed var(--ion-color-light);
  margin: 1px 0px;
  background: url(/static/media/last-stamp-background.682e5a5d.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.stamp-photo .stamp-text {
  font-family: var(--ion-font-family);
}

.stamp-photo:nth-last-child(1),
.stamp-photo:nth-last-child(2) {
  border-bottom: none;
}

.stamp-photo:nth-last-child(2n + 1) {
  border-right: none;
  position: relative;
}

.stamps .stamp-photo ion-icon {
  font-size: 70px !important;
}

.stamp-photo:nth-last-child(1) .stamp-text {
  width: 50px;
  height: 35px;
  position: absolute;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 16px;
  z-index: 1;
}

.stamp-photo:nth-last-child(2) .stamp-text,
.stamp-photo:nth-last-child(3) .stamp-text,
.stamp-photo:nth-last-child(4) .stamp-text,
.stamp-photo:nth-last-child(5) .stamp-text,
.stamp-photo:nth-last-child(6) .stamp-text,
.stamp-photo:nth-last-child(7) .stamp-text,
.stamp-photo:nth-last-child(8) .stamp-text,
.stamp-photo:nth-last-child(9) .stamp-text,
.stamp-photo:nth-last-child(10) .stamp-text {
  display: none;
}

.web .stamps .stamp-photo ion-icon {
  font-size: 120px !important;
}

.web .stamp-photo:nth-last-child(1) .stamp-text {
  width: 95px;
  height: 50px;
  position: absolute;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 18px;
  z-index: 1;
}

.web .stamp-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px dashed var(--ion-color-light);
  border-right: 2px dashed var(--ion-color-light);
  margin: 1px 0px;
}

.web .stamp-photo:nth-last-child(1),
.stamp-photo:nth-last-child(2) {
  border-bottom: none;
}

.web .stamp-photo:nth-last-child(2n + 1) {
  border-right: none;
}

.scan-title {
  margin-bottom: 5px !important;
}

.tabs {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  overflow: hidden;
  z-index: 0;
}

.tabs .segment-holder {
  position: sticky;
  position: -webkit-sticky;
  flex: 0 0 auto;
  top: 0;
  z-index: 2;
}

.tabs ion-segment-button {
  --indicator-color: transparent;
  font-size: 12px;
}

.tabs ion-segment-button > ion-label {
  text-transform: uppercase;
  font-weight: bold;
}

.tabs ion-slides,
ion-slide {
  flex: 1 1 auto;
  margin: 0;
  height: calc(100vh - 163px);
}

.loyalty-bar > div {
  flex: 1 1 auto;
  text-align: left;
}

.loyalty-bar > div:first-child {
  flex: 0 0 20px;
  margin-left: 24px;
}

.loyalty-bar > div:nth-of-type(2) > ion-label {
  font-weight: bold;
}

.loyalty-bar > div:last-child {
  text-align: right;
}

.loyalty-bar .loyalty-badge {
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  margin: 0 0 0 24px;
}

.loyalty-bar .loyalty-balance {
  margin: 0 24px 0 0;
  font-weight: bold;
}
.loyalty-badge {
  width: var(--okx-loyalty-badge-width);
  position: relative;
  border-radius: 50%;
  padding: var(--okx-loyalty-badge-inner-padding);
  background-color: #fcba29;
  border: 2px solid #c58d13;
  margin: 20px auto;
}

.loyalty-badge > div {
  padding-top: calc(100% - 4px);
  border-radius: 50%;
  border: inherit;
}

.loyalty-badge > div::before,
.loyalty-badge > div::after {
  content: '';
  display: block;
  position: absolute;
  right: 6px;
  bottom: 8px;
  background-color: #fcba29;
  width: 2px;
  height: 5px;
  -webkit-transform: rotate(-40deg);
          transform: rotate(-40deg);
}

.loyalty-badge > div::after {
  right: 10px;
  bottom: 6px;
}

.loyalty-badge > div ion-icon {
  --lb-icon-offset: calc(
    var(--okx-loyalty-badge-width) / 2 - var(--okx-loyalty-badge-inner-padding)
  );
  position: absolute;
  left: var(--lb-icon-offset);
  top: var(--lb-icon-offset);
  font-size: 20px;
  margin: -9px 0 0 -8px;
  color: #c58d13;
}

.loyalty-bar {
  position: absolute;
  width: 100vw;
  height: var(--okx-loyalty-bar-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  background-color: var(--ion-color-secondary);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  left: 0;
  bottom: 0;
  z-index: 2;
}

.loyalty-bar ion-label {
  color: white !important;
}

.loyalty-balance-label {
  padding-left: 20px;
  font-weight: bold;
}

.web .loyalty-bar {
  position: static;
  width: auto;
}

:root {
  --clickup-initial-height: 50px;
  --clickup-open-height: 50%;
}

.clickup {
  position: absolute;
  position: fixed;
  left: 0;
  top: calc(100% - 50px);
  top: calc(100% - var(--clickup-initial-height));
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transition: top 200ms ease-out;
  z-index: 5;
}

.clickup.open {
  top: calc(100% - 50%);
  top: calc(100% - var(--clickup-open-height));
}

.clickup-bar {
  background-color: var(--ion-color-secondary);
  flex: 0 0 50px;
  flex: 0 0 var(--clickup-initial-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.clickup-bar > div {
  flex: 1 1 auto;
  text-align: right;
  color: #fff;
  font-weight: bold;
}

.clickup-caret {
  height: 20px;
  background: transparent url(/static/media/caret-up-white.c66d710b.svg) no-repeat 5px 50%;
}

.clickup.open .clickup-caret {
  background-image: url(/static/media/caret-down-white.2c7477c5.svg);
}

.clickup-content {
  flex: 1 1 auto;
  background-color: #fff;
  position: relative;
}

:root {
  --voucher-red: #6e2b13;
  --voucher-black: #000;
}
.web ion-content.route-apply-vouchers  > .ion-padding  {
 height: 70%;
 top: 0 !important; 
 bottom: 0 !important; 
 left: 0 !important; 
 right: 0 !important; 
 margin: auto !important; 
}

.voucher {
  margin-bottom: 20px;
}

.voucher.green .voucher-content {
  background-color: var(--ion-color-primary);
}

.voucher.red .voucher-content {
  background-color: #6e2b13;
  background-color: var(--voucher-red);
}

.voucher.black .voucher-content {
  background-color: #000;
  background-color: var(--voucher-black);
}

.voucher-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.voucher-code-description {
  font-weight: 600;
  padding: 0px 30px;
  letter-spacing: 0.5px;
}

.voucher-content::before,
.voucher-content::after {
  display: block;
  content: '';
  position: absolute;
  top: 8px;
  bottom: 0;
  width: 20px;
  background: radial-gradient(#fff 0px, #fff 6px, transparent 7px, transparent);
  background-size: 20px 20px;
}

.voucher-content::before {
  left: -9px;
}

.voucher-content::after {
  right: -9px;
}

.voucher-content div {
  color: #fff;
}

.voucher-spacer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 20px;
  min-width: 20px;
}

.voucher-info,
.voucher-stamp {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  text-align: center;
}

.voucher-stamp {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 85px;
  min-height: 112px;
  min-width: 85px;
  position: relative;
}

.voucher-stamp::after {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 70px;
  height: 70px;
  margin: -38px 0 0 -38px;
  border-radius: 50%;
  border: 3px #fff solid;
  background: transparent url(/static/media/social.e6f5af8e.svg) no-repeat 50% 50%;
  background-size: 40px 40px;
}

.voucher:not(.default-font) .voucher-title,
.voucher:not(.default-font) .voucher-subtitle {
  font-family: 'Roboto';
}

.voucher-title,
.voucher-subtitle {
  text-transform: uppercase;
}

.voucher-title {
  font-size: 32px;
  font-weight: bold;
}

.voucher-subtitle {
  font-size: 12px;
}

.voucher-description {
  font-size: 8px;
}

.voucher.default-font .voucher-title {
  line-height: 28px;
  font-weight: normal;
}

.voucher.default-font .voucher-subtitle {
  font-weight: bold;
}

.voucher-bar {
  display: flex;
  align-items: center;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.voucher-bar > div:first-child,
.voucher-bar > div:last-child {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.voucher-bar > div:first-child {
  padding-left: 16px;
}

.voucher-bar > div:last-child {
  text-align: right;
  padding-right: 12px;
}

.voucher-bar ion-button {
  margin-bottom: 4px;
  --border-width: 1px;
  --border-color: rgba(var(--ion-color-gray-rgb), 0.5);
  color: var(--ion-color-secondary);
  font-weight: bold;
  height: 23px !important;
  font-size: 9px;
  text-transform: uppercase;
  border: 1px solid rgba(var(--ion-color-gray-rgb), 0.5) !important;
  border-radius: 3px;
  padding-top: 1px;
}

.voucher-bar p {
  font-size: var(--okx-small-text-font-size);
}

ion-modal.modal-classic.voucher-modal {
  --width: 85%;
  --height: 70%;
  --border-radius: 10px;
}

ion-modal.modal-classic.voucher-modal .modal-classic-wrapper {
  padding: 0;
}

ion-modal.modal-classic.voucher-modal .modal-classic-header {
  background-color: var(--ion-color-secondary);
  padding-left: 20px;
}

ion-modal.modal-classic.voucher-modal .modal-classic-header h3 {
  color: var(--ion-color-white);
  font-weight: bold;
  text-transform: uppercase;
}

ion-modal.modal-classic.voucher-modal .modal-classic-closer {
  color: var(--ion-color-white);
  font-size: 18px;
}

ion-modal.modal-classic.voucher-modal .modal-classic-content {
  padding: 30px 15px;
}

.voucher-modal-content {
  padding: 0 30px;
}

.modal-classic-content > div > img,
.voucher img {
  object-fit: cover;
  object-position: center;
}

#main
  > ion-content.route-vouchers
  > .ion-padding
  .absolute-content
  .voucher-modal-content
  > ion-text
  > span {
  font-size: 23px;
}

#main > ion-content.route-vouchers > .ion-padding .absolute-content ion-item {
  --border-color: var(--ion-color-light);
}

#main > ion-content.route-vouchers > .ion-padding .absolute-content .inline-input > ion-text {
  flex: 0 0 25%;
}

.voucher-modal-qr {
  background-size: contain;
  width: 100%;
  height: 200px;
}

.voucher-item ion-item ion-input {
  --placeholder-color: var(--ion-color-primary);
  --placeholder-opacity: 1;
  text-transform: none;
  text-transform: initial;
  -webkit-margin-start: 20px;
          margin-inline-start: 20px;
}

.web .static.route-vouchers {
  background: transparent none;
}

.web ion-content.route-vouchers {
  --background: transparent none;
  --ion-color-white: transparent;
}

.web #main > ion-content.route-vouchers > .ion-padding .absolute-content {
  border-radius: inherit;
  overflow: hidden;
  padding: 0;
}

.web #main > ion-content.route-vouchers > .ion-padding .absolute-content .flex-min:first-of-type {
  padding: var(--ion-padding) var(--ion-padding) 0 var(--ion-padding);
}

.web #main > ion-content.route-vouchers > .ion-padding .absolute-content > .scrollable-y {
  padding: 0 var(--ion-padding);
}

.web .clickup {
  position: absolute;
}

.voucher-image {
  position: relative;
  display: flex;
  justify-content: center;
}
.voucher-image img{
  height: 150px;
  width: auto;
;
}
.no-data-second-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.no-data-second-topic {
  min-width: 310px;
  height: auto;
  display: flex;
  justify-content: center;
}

.no-data-second-topic > img {
  width: calc(100% - 20%);
  object-fit: cover;
  opacity: 0.7;
}


/* .left-circles{
	position: absolute;
    width: 25px;
    height: 23px;
    shape-outside: ellipse(50% 50%);
    clip-path: ellipse(50% 50%);
    background: white;
    left: -4%;
}

.right-circles{
	position: absolute;
    width: 25px;
    height: 23px;
    shape-outside: ellipse(50% 50%);
    clip-path: ellipse(50% 50%);
    background: white;
    right: -4%;
}  */

.first-circle{
    top: 16px;
}
.second-circle{
    top: 52px;
}
.third-circle{
    top: 88px;
}
.fourth-circle{
    top: 124px;
}
.fifth-circle{
    top: 160px;
}
.input-avatar {
  display: none;
}

.profile-image-content {
  width: 96px;
  height: 96px;
  margin: auto;
}

.avatar-image-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.avatar-delete {
  position: absolute;
  top: 10%;
  right: 115px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.avatar-photo {
  position: absolute;
  top: 50%;
  right: -20px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  background: rgba(var(--ion-color-secondary-rgb), 1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-circle {
  border: 10px solid var(--okx-circle-color);
  border-radius: 65px;
  position: relative;
}

.account-inputs .ion-text-wrap > ion-note {
  color: var(--ion-color-primary);
}

.account-inputs ion-button.link {
  font-size: var(--okx-small-text-font-size);
}

.line {
  height: 1px;
  background-color: var(--ion-color-tertiary);
  margin: 20px 0;
}

.top {
  margin-top: 25px;
}

#main > ion-content.route-account > .ion-padding ion-input > input,
#main > ion-content.route-account > .ion-padding input {
  font-weight: bold;
}

#main > ion-content.route-account > .ion-padding ion-label,
#main > ion-content.route-account > .ion-padding label {
  color: var(--ion-color-gray);
  font-weight: 500;
}

#main > ion-content.route-account > .ion-padding .mbsc-select-input.mbsc-control {
  border-bottom: 0;
}

@supports (-webkit-overflow-scrolling: touch) {
  /* iOS */
  .avatar-delete {
    position: absolute;
    top: 10%;
    right: 95px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
}

.web ion-content.route-account {
  --background: transparent;
  --ion-color-white: transparent;
}

.web #main > ion-content.route-account > .ion-padding {
  position: fixed;
  left: 50%;
  top: 50px;
  bottom: 50px;
  width: 450px;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  margin-left: 0;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web .route-account .ion-padding .frm-wrapper,
.web .route-account .ion-padding .top-medium {
  position: absolute;
}

.web .route-account .ion-padding .frm-wrapper {
  left: 32px;
  right: 32px;
  top: 40px;
  bottom: 130px;
  overflow-y: auto;
}

.web .route-account .ion-padding .top-medium {
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 32px 32px;
}

.web .route-account .ion-padding ion-button:last-child {
  margin-bottom: 0;
}

.web .avatar-photo,
.web .avatar-delete {
  cursor: pointer;
}

.account-inputs ion-input {
  --padding-top: 0px !important;
}

.ios .account-inputs ion-input {
  margin-top: -20px;
}

.account-inputs ion-item {
  height: 56px;
}

.account-inputs {
  margin-top: 10px;
}

.ios .account-inputs {
  margin-top: 20px;
}

ion-content div.refer-a-friend {
  background-image: url(/static/media/refer-friend.eaf6079f.png);
  background-position: 40% 70%;
  background-repeat: no-repeat;
}

ion-content div.refer-a-friend::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 240px;
  z-index: 1;
  background-image: linear-gradient(rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 90%);
}

.raf-card {
  border-radius: var(--ion-card-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: 0;
}

.raf-card ion-card-content {
  padding: 30px;
  font-size: var(--ion-font-size);
}

.raf-card ion-card-content .raf-info-title {
  padding: 0 2px;
  font-weight: bold;
}

.raf-card ion-card-content .raf-info {
  padding: 15px 15px 30px;
  text-align: center;
}

#main > ion-content.route-refer-a-friend > .ion-padding ion-item {
  --border-color: var(--ion-color-light);
}

.web .static.route-refer-a-friend {
  background: transparent none;
}

.web ion-content.route-refer-a-friend {
  --background: transparent none;
  background-color: #fff;
  background-image: url(/static/media/refer-friend.eaf6079f.png);
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.web #main > ion-content.route-refer-a-friend > .ion-padding .absolute-content {
  background: transparent none;
  border-radius: inherit;
  overflow: hidden;
}

.web #main > ion-content.route-refer-a-friend > .ion-padding {
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-left: 0;
  max-height: var(--okx-bsmodal-width-sm);
  height: 100%;
}

.web .raf-card {
  border-radius: inherit;
  box-shadow: none;
}

.refer-fiend-email{
  opacity: 0.8;
}
.feedback-label {
  font-family: var(--ion-default-font-light);
  font-size: 13px;
  /* color: var(--ion-feedback-label); */
  margin: 5px;
  font-weight: bold;
}

.star-holder {
  padding: 10px 0px;
}

.star {
  font-size: 26px;
  color: var(--ion-feedback-star);
  margin-right: 15px;
}

.web .star {
  cursor: pointer;
}

.star:last-child {
  margin: 0;
}

.frm-wrapper .box-holder {
  padding: 0;
}

.frm-wrapper .box-holder .inline-input {
  padding: 5px;
}

.frm-wrapper .box-holder ion-checkbox {
  width: 18px;
  height: 18px;
  margin: 0 10px;
}

.box-holder-top {
  margin-top: 20px;
}

.frm-wrapper .box-holder .list-md {
  padding-top: 0;
  padding-bottom: 0;
}

.separator {
  height: 1px;
  background-color: var(--ion-separator-color);
  background-color: rgba(var(--ion-color-light-rgb), 1);
  margin: 0 5px;
}

.native-textarea.sc-ion-textarea-ios {
  -webkit-padding-start: 5px;
          padding-inline-start: 5px;
}

.commentService {
  height: 50px;
}

.commentTech {
  height: 160px;
}

.item.sc-ion-label-ios-h,
.item .sc-ion-label-ios-h,
.item .sc-ion-label-md-h {
  font-weight: normal;
  margin: 0;
}

/* .item.sc-ion-label-ios-h, .item .sc-ion-label-md-h {
  margin-left: 2px;
} */

#main > ion-content.route-feedback > .ion-padding .inline-input {
  border-bottom: 1px solid var(--ion-color-light);
}

#main > ion-content.route-feedback > .ion-padding .location-select {
  border-bottom: 1px solid var(--ion-color-black);
}

.stars-wrapper ion-icon {
  font-size: 16px;
  margin-right: 5px;
}

.stars-wrapper {
  margin: 5px 0 !important;
}

#main > ion-content.route-feedback > .ion-padding .inline-input label {
  flex: none;
  font-family: var(--ion-default-font-light);
  font-size: 13px;
  -webkit-margin-end: 50px;
          margin-inline-end: 50px;
  margin-top: 0;
}

#main > ion-content.route-feedback > .ion-padding .inline-input:first-child .feedback-label {
  margin: 0;
  padding-left: 5px;
  display: flex;
  align-items: center;
}

.alert-message.sc-ion-alert-ios {
  color: var(--ion-color-primary);
  font-family: var(--ion-default-font-thiner);
}

#main > ion-content.route-feedback > .ion-padding .mbsc-select-input.mbsc-control {
  border: 0;
}

#main
  > ion-content.route-feedback
  > .ion-padding
  .select-picker-input--feedback
  + input.mbsc-select-input.mbsc-control {
  font-size: 14px;
}

#main > ion-content.route-feedback > .ion-padding .top-medium {
  margin-top: 15px;
}

#main > ion-content.route-feedback > .ion-padding .top-medium .inline-input.inline-input--feedback {
  align-items: center;
  border-bottom: 0;
}

.web ion-content.route-feedback {
  --background: transparent none;
  --ion-color-white: transparent;
}

.web
  #main
  > ion-content.route-feedback
  > .ion-padding
  .top-medium
  .inline-input.inline-input--feedback {
  align-items: center;
  border-bottom: 0;
}

.web #main > ion-content.route-feedback > .ion-padding {
  width: 450px;
  margin-left: 0;
  height: 85%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.web .route-feedback .ion-padding .heading-wrapper,
.web .route-feedback .ion-padding .frm-wrapper,
.web .route-feedback .ion-padding .action-wrapper {
  padding: 0 32px;
}

.web .route-feedback .ion-padding .frm-wrapper {
  position: absolute;
  left: 32px;
  right: 32px;
  top: 100px;
  bottom: 100px;
  overflow-y: auto;
  --ion-color-success: var(--ion-color-primary);
}

.web .route-feedback .ion-padding .action-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 32px;
}

.web .route-feedback .ion-padding ion-button:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 1441px) {
  .web #main > ion-content.route-feedback > .ion-padding {
    height: 70%;
  }
}

.feedback-alert .alert-head.sc-ion-alert-md {
  text-align: center;
}

.feedback-alert .alert-message.sc-ion-alert-md {
  font-size: 13px;
  color: black;
  text-align: center;
}

.feedback-alert .alert-button-group.sc-ion-alert-md {
  justify-content: center;
  border-top: 1px solid var(--ion-color-gray-tint);
  padding: 0px;
}

.feedback-alert .alert-wrapper.sc-ion-alert-md {
  border-radius: 10px !important;
}
.history-content {
  text-align: center;
  padding: 20px;
}

.history-item {
  border-bottom: 1px solid var(--ion-separator-color);
  text-transform: uppercase;
}

.history-item-is-hidden{
  display: none;
}

.history-item h2 {
  margin-top: 4px;
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1;
}

.history-item ion-col:first-child {
  text-align: justify;
}

.history-item ion-text span.small-text {
  text-transform: none;
  text-transform: initial;
  text-overflow: ellipsis;
  overflow: hidden;
}

.history-item ion-col p:first-child {
  color: var(--ion-color-gray);
  font-size: 8px;
  margin-bottom: 8px;
  font-weight: bold;
}

.history-item ion-col p:last-child {
  color: var(--ion-color-medium);
  font-size: 9px;
  margin-top: 2px;
  margin-bottom: 10px;
}

.history-item ion-col:last-child p {
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-end;
  text-align: right;
  font-size: 12px;
  margin-top: 25px;
  font-weight: bold;
  color: var(--ion-color-primary);
  margin: 0;
}

.history-item ion-col:last-child p img {
  width: 18px;
  max-width: 100%;
  margin-right: 10px;
}

.history-item ion-col:last-child p.redeem {
  font-size: 15px;
  margin: 0;
}

.order-button-wrapper .order-button {
  padding: 3px 8px 2px 8px;
  display: inline-flex;
  justify-content: center;
  font-size: 9px;
  cursor: pointer;
}

.order-button-wrapper .order-button ion-icon {
  font-size: 10px;
}

.order-location-name {
  text-transform: capitalize;
}

.web ion-content.route-history {
  --background: transparent none;
  --ion-color-white: transparent;
}

.web #main > ion-content.route-history > .no-padding {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /* top: 100px;
bottom: 100px;
width: 400px;
margin-left: -200px; */
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  /* min-height: 450px; */
  width: var(--okx-bsmodal-width-lg);
  height: 75%;
}

.web .route-history .tabs {
  position: relative;
  height: calc(100% - 60px);
  border-radius: inherit;
}

.history-details-description {
  width: 100px;
}

.order-again-button {
  font-size: 15px !important;
  height: 25px !important;
  --border-color: black !important;
  border-radius: 20px !important;
}

@media screen and (min-width: 1441px) {
  .web #main > ion-content.route-history > .no-padding {
    height: 65%;
  }
}

.okx-box {
	border: 1px solid var(--okx-border-color);
	border-radius: var(--okx-box-radius);
	padding-left: var(--okx-box-padding-left);
	padding-top: var(--okx-box-padding-top);
	padding-right: var(--okx-box-padding-right);
	padding-bottom: var(--okx-box-padding-bottom);
}

.okx-box-header {
	margin-left: calc(0px - var(--okx-box-padding-left));
	margin-top: calc(0px - var(--okx-box-padding-top));
	margin-right: calc(0px - var(--okx-box-padding-right));
	padding-left: var(--okx-box-padding-left);
	padding-right: var(--okx-box-padding-right);
	border-bottom: 1px solid var(--okx-border-color);
}

.okx-box-footer {
	margin-left: calc(0px - var(--okx-box-padding-left));
	margin-bottom: calc(0px - var(--okx-box-padding-bottom));
	margin-right: calc(0px - var(--okx-box-padding-right));
	padding-left: var(--okx-box-padding-left);
	padding-right: var(--okx-box-padding-right);
	border-top: 1px solid var(--okx-border-color);
}

.order-content-wrapper ion-row {
  color: var(--ion-color-dark);
  font-weight: bold;
}

.order-content-wrapper .basket-item-wrapper {
  border-bottom: 1px solid var(--okx-border-color);
  padding: 2px 0;
  opacity: 0.8;
}

.order-content-wrapper .basket-item-wrapper:last-of-type {
  border-bottom-width: 1px;
}

.order-content-wrapper .basket-item-wrapper .basket-item-service-charge-row {
  cursor: pointer;
}

.web .order-summary-sidebar .order-content-wrapper .basket-item-wrapper:last-of-type {
  border-bottom-width: 0px;
}

.web .order-summary-sidebar .order-content-wrapper .subtotal-bordered-bottom {
  border-top: 1px solid var(--okx-border-color);
}

.lefted {
  justify-content: left !important;
}

.sub-item-row {
  padding: 2px 0;
}

.total-bordered-bottom {
  border-bottom: 2px solid var(--okx-border-color);
}

ion-col.grow {
  flex-grow: 3.5;
}

.order-content-wrapper .basket-item-wrapper + ion-row > ion-col > ion-text > span {
  font-weight: normal;
}

.add-items-btn {
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 10px 0;
}

.add-items-btn.ion-color-black {
  --ion-color-base: var(--ion-color-secondary);
}

.total-bordered-bottom {
  border-bottom: 1px solid var(--ion-color-black);
}

.total-bordered-top {
  border-top: 1px solid var(--ion-color-black);
}

.subtotal-bordered-bottom {
  border-bottom: 1px solid var(--okx-border-color);
}

ion-col.grow {
  flex-grow: 3.5;
}

ion-modal.modal-classic.remove-apply-voucuher {
  --width: 70%;
  --height: 20%;
  --border-radius: 10px;
}

.web ion-modal.modal-classic.remove-apply-voucuher {
  --width: 500px;
  --height: 15vh;
}

.web ion-modal.modal-classic.remove-apply-voucuher .modal-classic-header {
  height: 100%;
  display: grid;
  place-content: center;
  text-transform: uppercase;
}

.incrementer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.incrementer-decrease,
.incrementer-incease {
  flex: 0 0 var(--okx-incrementer-button-width);
  height: var(--okx-incrementer-button-width);
  --padding-start: 0;
  --padding-end: 0;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-light);
  --background: #fff;
  --background-hover: var(--ion-color-light);
  --background-activated: var(--ion-color-light);
  --color: var(--ion-color-secondary);
  margin: 0;
}

.incrementer-quantity {
  flex: 1 1 auto;
  padding-left: var(--okx-incrementer-button-width);
  padding-right: var(--okx-incrementer-button-width);
}

.incrementer-quantity-solo {
  flex: 0 0 45px;
  text-align: center;
}

.incrementer-quantity-value {
  font-weight: bold;
}

.incrementer-note {
  font-size: var(--okx-small-text-font-size);
  color: var(--ion-color);
}

.web .incrementer-decrease,
.incrementer-incease {
  --color: var(--ion-color-secondary);
}

.service-charge-incrementer-wrapper {
  padding: 20px 0;
  margin: 20px 20px;
}
.service-charge-incrementer-wrapper p {
  text-align: center;
}

.incrementer-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  gap: 20px;
  padding: 0 32px;
  width: 100%;
}
.service-charge-modal .absolute-content,
.service-charge.absolute-content {
  padding: 30px 0;
}
.service-charge-incrementer-wrapper .incrementer-quantity {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.service-charge .incrementer ion-button {
  height: 44px;
  width: 44px;
  --border-radius: var(--okx-small-radius);
  margin: 10px;
}
.service-charge .title,
.service-charge-buttons {
  margin: 0 32px;
}

.service-charge-modal .modal-classic-content,
.service-charge-modal .modal-classic-wrapper {
  padding: 0 !important;
}
.service-charge-item {
  pointer-events: none;
}
.route-order .service-charge-item,
.route-order-summary .service-charge-item {
  cursor: pointer;
  pointer-events: all;
}
.service-charge-incrementer-wrapper .incrementer-quantity-value,
.service-charge-incrementer-wrapper .incrementer-note {
  font-size: 50px;
  font-family: var(--ion-default-font-bold);
}
.service-charge-intro-text {
  padding: 0px 34px;
}
.service-charge-intro-text .small-text {
  font-size: 12px;
}
.service-charge-percentage-text {
  color: var(--ion-color-secondary) !important;
}

.text-box {
  display: flex;
  justify-content: center;
  height: 40px;
  /* background: green; */
  align-items: center;
  font-weight: bolder;
}

/* .history-details {
	background: transparent url(../../assets/images/order-completed-bgd.jpg) no-repeat 100% 125%;
	background-size: 100% auto;
	z-index: 1;
} */

.order-status {
  width: auto;
  display: inline-block;
  font-family: var(--ion-default-font);
  font-size: var(--okx-small-text-font-size);
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid var(--ion-color-primary);
  padding: 2px 8px;
  margin-bottom: 5px;
  line-height: 1;
}

.history-details::after {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  content: '';
  background-image: linear-gradient(#fff 82%, rgba(255, 255, 255, 0) 115%);
}

.history-details ion-grid {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
}

.history-details ion-col {
  font-weight: normal;
}

.history-details .okx-box-footer {
  border-top: 2px solid var(--okx-border-color);
  padding-left: 0;
  margin-left: 0;
  padding-right: 0;
  margin-right: 0;
}

.history-details .okx-box-footer ion-row {
  border-bottom: 0 none;
}

.history-details .okx-box-header-grid ion-row:nth-of-type(1n) {
  border: 0;
  opacity: 0.8;
}

.history-details .okx-box-header-grid ion-row {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  height: 18px;
}

.history-details .okx-box-header-grid ion-row ion-col:nth-of-type(2) {
  display: flex;
  justify-content: flex-end;
  opacity: 0.8;
  padding: 2px;
}

.history-details .okx-box-header-grid ion-row ion-col:nth-of-type(1) ion-text {
  white-space: nowrap;
}

.web ion-content.route-order-completed {
  --background: transparent none;
  --ion-color-white: transparent;
}

.web ion-content.route-order-completed {
  background-color: #fff;
  background-image: url(/static/media/refer-friend.eaf6079f.png);
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.web #main > ion-content.route-order-completed > .ion-padding {
  position: absolute;
  left: 50%;
  top: 50%;
  /* bottom: 50px; */
  width: var(--okx-bsmodal-width-lg);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-left: 0;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  padding: 64px;
  min-height: 75%;
}
.web #main > ion-content.route-history-details > .ion-padding {
  height: 75%;
  top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  left: 0 !important;
  margin: auto !important;
  min-height: 80%;
  overflow: auto;
 }
 
.web #main > ion-content.route-order-completed > .ion-padding > .absolute-content.history-details {
  background: none;
  overflow-y: auto;
}

.web
  #main
  > ion-content.route-order-completed
  > .ion-padding
  > .absolute-content.history-details:after {
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web
  #main
  > ion-content.route-order-completed
  > .ion-padding
  > .absolute-content.history-details
  > div.scrollable-y {
  height: 100%;
}

.web .history-details .okx-box,
.web .history-details .okx-box-header {
  border-color: var(--okx-border-color);
  border-width: 1px;
}

.web .history-details .okx-box-header-grid ion-row:not(:last-child) ion-col {
  /* padding: 7px 5px 0 5px; */
}

.web .history-details .okx-box-header-grid ion-row:first-of-type ion-col {
  margin-top: 5px;
}

.web .history-details .okx-box-header-grid ion-row:last-of-type ion-col {
  padding-bottom: 7px;
}

.web .history-details .okx-box-header-grid ion-row ion-col:nth-of-type(odd) strong {
  font-weight: normal;
}

.web .history-details .okx-box-header-grid ion-row ion-col:nth-of-type(even) {
  text-align: right;
  font-weight: bold;
}

@media screen and (min-width: 1441px) {
  .web #main > ion-content.route-history-details > .ion-padding {
    min-height: 65%;
  }
}

.mb-6 {
  margin-bottom: 6px;
}
:root {
  --okx-puller-height: 30px;
}

.okx-pullup {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.okx-puller {
  min-height: 30px;
  min-height: var(--okx-puller-height);
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-direction: column;
  padding-top: 18px;
}

.okx-pullup-200 {
  top: 300px !important;
}

.okx-pullup-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 35px;
  bottom: 0;
  overflow-y: auto;
}

.okx-pullup-content-top-75 {
  top: 20px;
}

.okx-pullup-content-top-110 {
  top: 110px;
}

.notch {
  width: 50px;
  height: 6px;
  background: rgb(var(--ion-color-gray-rgb), 0.6);
  border-radius: 3px;
  align-self: center;
  position: absolute;
  top: 12px;
}

.okx-pullup-content-header .title {
  color: white !important;
}

.okx-pullup-content-header .item-details-card-price {
  color: white !important;
}
.okx-pullup-200 {
  top: 300px !important;
}

.gm-holder {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.map-holder {
  position: relative;
  height: 100%;
}

.map {
  width: 100%;
  height: 100%;
}

.gm-style .map-location-holder {
  z-index: 9999999;
}

.gm-style {
  height: 50% !important;
}

.map-location-pullup {
  background-color: #fff;
  border-top-left-radius: var(--ion-card-radius);
  border-top-right-radius: var(--ion-card-radius);
}

.map-location-holder {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: var(--ion-color-box-holder);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.map-location-holder {
  border-radius: var(--ion-card-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #fff;
}

.map-location-content {
  flex: 1 1 100%;
  display: flex;
  justify-content: stretch;
  align-items: center;
  padding-top: 0;
}

.map-location-content > * {
  flex: 1 1 auto;
}

.map-location-content > .map-location-nav {
  flex: 0 0;
}

.map-location-content > .map-location-nav ion-button {
  font-size: 18px;
  --padding-start: 0;
  --padding-end: 0;
}

.map-location-content .swiper-slide {
  display: block;
  text-align: left;
}

.map-location-content ion-content {
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 0;
}

.map-location-content ion-content ion-slide > div {
  padding-bottom: 20px;
  margin-left: 2px;
}

.map-location-content .sectiontitle {
  font-weight: bold;
  margin: 0;
  letter-spacing: 0.024em;
  font-size: 12px !important;
}

.web .map-location-content .title {
  margin-top: 0px !important;
}

.location-info > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.location-times {
  white-space: pre-line;
}

.location-time {
  display: flex;
  justify-content: flex-end;
}

@supports (-webkit-overflow-scrolling: touch) {
  .map-location-content ion-content {
    position: absolute;
    left: 50px;
    right: 50px;
    top: 0;
    bottom: 0;
    width: auto;
  }
  .map-location-content > .map-location-nav {
    position: absolute;
    width: 50px;
  }
  .map-location-content > .map-location-nav:first-child {
    left: 0;
  }
  .map-location-content > .map-location-nav:last-child {
    right: 0;
  }
}

.map-small-text {
  font-weight: 100 !important;
}

.web .gm-style {
  height: 100% !important;
}
.web .static.route-locations {
  display: none;
}

.web ion-header.route-locations + ion-toolbar.dynamic-header,
.web ion-header.route-locations + .content-spinner + ion-toolbar.dynamic-header {
  position: absolute;
  left: 10px;
  top: 84px !important;
  width: 60px !important;
}

.web .route-locations .map {
  width: calc( 100% - 350px ) !important;
}

.web .route-locations .okx-pullup.map-location-pullup {
  top: 0 !important;
  bottom: 0 !important;
  width: 350px;
  left: auto;
  right: 0;
  margin-left: 0;
  border-radius: 0;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web .route-locations .okx-pullup-content {
  top: 120px;
  bottom: 32px !important;
}

.web .map-location-content {
  align-items: flex-start;
}

.web .map-location-nav {
  margin-top: 64px;
  padding: 0 5px;
}

.web .okx-puller {
  display: none;
}

@media screen and (max-width: 640px) {
  .web .route-locations .map {
    width: 100% !important;
    height: 70% !important;
  }
  .web .route-locations .okx-pullup.map-location-pullup {
    top: 70% !important;
    width: 100%;
    left: 0;
  }
  .web .route-locations .okx-pullup-content {
    top: 32px;
    bottom: 32px !important;
  }
  .web .map-location-content {
    align-items: center;
  }
  .web .map-location-nav {
    margin-top: 0;
  }
}

ion-grid.no-padding.social ion-col {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
}
.square {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.square:after {
  content: '';
  display: block;
  padding-bottom: 44px;
}


.content {
  position: absolute;
  display: flex;
  background-color: var(--ion-color-secondary);
  width: 100%;
  height: 39px;
  border: 1px solid var(--ion-color-secondary);
  border-radius: 1px;
}

.soc-icon {
  display: flex;
  border-radius: 50%;
  width: 15%;
  height: auto;
  align-items: center;
  justify-content: center;
  font-size: 21px;
}

.soc-label {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.soc-label.small-text {
  color: var(--ion-color-white);
  font-size: 12px;
}

.web .route-social.ion-color-white {
  --background: transparent none;
  --ion-color-base: transparent;
}

.web #main > ion-content.route-social > .ion-padding {
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: 50px;
  width: var(--okx-bsmodal-width-lg);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-left: 0;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  padding: 64px;
  min-height: 70%;
  overflow-y: auto;
}

.web .square {
  cursor: pointer;
}

.web .route-social .ion-padding .heading-wrapper,
.web .route-social .ion-padding .frm-wrapper,
.web .route-social .ion-padding .action-wrapper {
  padding: 0 32px;
}

.web .route-social .ion-padding .frm-wrapper {
  position: absolute;
  left: 32px;
  right: 32px;
  top: 120px;
  bottom: 64px;
  overflow-y: auto;
  --ion-color-success: var(--ion-color-primary);
}

@media screen and (min-width: 1441px) {
  .web #main > ion-content.route-social > .ion-padding {
    min-height: 70%;
  }
}

.okx-password-wrapper {
	display: flex;
	align-items: center;
	width: 100%;
}

.okx-password-wrapper .okx-password-holder {
	flex: 1 1 auto;
}

.okx-password-wrapper ion-button {
	flex: 0 0 auto;
}

.new-user {
  color: var(--ion-color-primary);
  margin-right: 5px;
  text-transform: none;
  text-transform: initial;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.web #main > ion-content.route-login > .ion-padding {
  position: relative;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  padding: 32px;
  min-height: 500px;
  max-height: 75%;
  top: 50%;
  -webkit-transform: translate(0%, -45%);
          transform: translate(0%, -45%);
}

.web #main > ion-content.route-login > .ion-padding > .absolute-content {
  padding: 32px;
  overflow-y: auto;
}

@media screen and (min-width: 1441px) {
  .web #main > ion-content.route-login > .ion-padding {
    max-height: 60%;
  }
}

@media screen and (max-height: 600px) {
  .web #main > ion-content.route-login > .ion-padding {
    min-height: calc(100% - 125px);
    box-shadow: none;
  }
}

.toggle {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

/* .register-background { */
/* --background: url(../../assets/images/login-01.png) transparent no-repeat 50% 100% / 100% auto; */
/* } */

.box-holder.box-holder--register h2 {
  font-weight: 600;
}

#main > ion-content.route-register > .ion-padding .box-holder.box-holder--register {
  border-color: rgba(var(--ion-color-light-rgb), 1);
}

#main > ion-content.route-register > .ion-padding ion-list > ion-item {
  --border-color: rgba(var(--ion-color-light-rgb), 1);
}

.web #main > ion-content.route-register > .ion-padding {
  position: relative;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: none;
  padding: 32px;
  min-height: 630px;
  max-height: 75%;
  top: 50%;
  -webkit-transform: translate(0%, -45%);
          transform: translate(0%, -45%);
  margin-top: 5px;
  padding: 0px;
}

.web #main > ion-content.route-register > .ion-padding > .absolute-content {
  padding: 32px;
}

@media screen and (min-width: 1441px) {
  .web #main > ion-content.route-register > .ion-padding {
    min-height: 650px;
    max-height: 65%;
    padding: 0px;
  }
}

@media screen and (max-height: 720px) {
  .web #main > ion-content.route-register > .ion-padding {
    min-height: calc(100% - 125px);
    box-shadow: none;
    padding: 0px;
  }
}

.register-toggle {
  margin-right: 10px;
}

.ios .register-toggle {
  margin-right: 0px;
}

.route-register .absolute-content {
	overflow: auto;
}
/* .reset-password-background { */
/* background: url(../../assets/images/login-01.png) transparent no-repeat 50% 100%; */
/* background-size: 100% auto; */
/* } */

.web #main > ion-content.route-reset-password > .ion-padding {
  position: relative;
  /* width: 50%;
	max-width: 400px;
	min-width: 280px;
	margin-top: 60px;
	margin-left: auto;
	margin-right: auto; */
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  padding: 48px 32px 5px;
  height: 55%;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
}

#main > ion-content.route-reset-password > .ion-padding ion-list > ion-item {
  --border-color: rgba(var(--ion-color-light-rgb), 1);
}

.web .route-reset-password .ion-padding .absolute-content {
  padding: 48px 32px 40px;
}

@media screen and (min-width: 1441px) {
  .web #main > ion-content.route-reset-password > .ion-padding {
    height: 45%;
  }
}

.web .route-terms {
  --background: transparent none;
  --ion-color-white: transparent;
}

.web #main > ion-content.route-terms > .ion-padding {
  position: fixed;
  /* left: 50%;
	top: 50px;
	bottom: 50px;
	width: 560px; */
  margin-left: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 75%;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web .route-terms .ion-padding > div {
  overflow-y: auto;
  max-height: 100%;
  padding-right: 8px;
}

@media screen and (min-width: 1441px) {
  .web #main > ion-content.route-terms > .ion-padding {
    height: 65%;
  }
}

.web .route-privacy {
  --background: transparent none;
  --ion-color-white: transparent;
}

.web #main > ion-content.route-privacy > .ion-padding {
  position: fixed;
  /* left: 50%;
	top: 50px;
	bottom: 50px;
	width: 560px; */
  margin-left: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 75%;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web .route-privacy .ion-padding > div {
  overflow-y: auto;
  max-height: 100%;
  padding-right: 8px;
}

@media screen and (min-width: 1441px) {
  .web #main > ion-content.route-privacy > .ion-padding {
    height: 65%;
  }
}

.web .route-faq {
  --background: transparent none;
  --ion-color-white: transparent;
}

.web #main > ion-content.route-faq > .ion-padding {
  position: fixed;
  /* left: 50%;
	top: 50px;
	bottom: 50px;
	width: 560px; */
  margin-left: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 75%;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web .route-faq .ion-padding > div {
  overflow-y: auto;
  max-height: 100%;
  padding-right: 8px;
}

@media screen and (min-width: 1441px) {
  .web #main > ion-content.route-faq > .ion-padding {
    height: 65%;
  }
}

:root {
	--clickup-initial-height: 50px;
	--clickup-open-height: 50%;
}

.pill {
	display: inline-block;
	border: 1px solid var(--ion-color-primary) !important;
	font-size: 9px;
	padding: 2px 6px;
	margin: 0 5px 5px 0;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 0.2px;
}

.pill-action {
	cursor: pointer;
}

.item-details-image {
  position: absolute;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 100vw;
  background: transparent none no-repeat 50% 0;
  background-size: cover;
}

.item-details-card-header h2,
.item-details-card-header h3,
.item-details-card-header h4 {
  margin-top: 0;
}

.item-details-card .okx-puller {
  border-bottom: 4px solid var(--ion-color-light);
}

.item-details-actions {
  position: absolute;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

ion-button.item-details-add-to-order {
  margin-top: 0;
  margin-bottom: 0;
  --background: var(--ion-color-secondary);
  --background-activated: rgba(var(--ion-color-tertiary-rgb), 0.2);
  color: var(--ion-color-contrast-secondary);
}

ion-button.item-details-add-to-order.greyed {
  --background: var(--ion-color-gray-tint);
  /* --background: var(--ion-color-secondary); */
  color: var(--ion-color-primary);
}

.item-details-wrapper .no-padding {
  background: var(--ion-color-primary-contrast);
}

.item-details-card {
  background-color: #fff;
  border-top-left-radius: var(--ion-card-radius);
  border-top-right-radius: var(--ion-card-radius);
}

.item-details-card-header {
  padding: 10px var(--ion-padding) 10px;
  z-index: 1;
  background: white;
}

.item-details-card-header .title {
  font-weight: bold;
  margin: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.item-details-card-content .sectiontitle {
  margin: 0;
}

.item-details-card-description,
.item-details-card-description p,
.item-details-card-description p span {
  color: var(--ion-color-primary) !important;
  font-size: 12px !important;
  opacity: 0.8;
}

span.item-details-card-description {
  display: inline-block;
  margin: 20px 0;
}
.menu-deal-group-wrapper {
  margin-top: 20px;
}
.item-details-card-price {
  text-align: right;
  font-size: 16px;
  font-weight: bold;
  color: var(--ion-color-secondary);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.item-details-card-content {
  padding: 0px 32px 120px 32px;
  margin: 20px 0 44px 0;
}

.item-details-actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  background-color: #fff;
  padding: 24px;
}

.item-details-actions ion-button {
  margin-top: 0;
  margin-bottom: 0;
}

.item-details-actions .incrementer {
  flex: 0 0 85px;
}

.item-details-actions .incrementer-quantity-solo {
  flex-basis: 30px;
}

.item-details-actions-quantity {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-details-remove,
.item-details-add {
  flex: 0 0 40px;
  height: 40px;
  --padding-start: 0;
  --padding-end: 0;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-light);
  --background: #fff;
  --background-hover: var(--ion-color-light);
  --color: var(--ion-color-primary);
}

.item-details-quantity {
  flex: 0 0 75px;
  text-align: center;
}

.item-details-background {
  background: var(--ion-color-gray) url(/static/media/logo-main.c2db7137.png) no-repeat 50% 50%/90%
    50%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  /* next line is portrait-only */
  opacity: 0.7;
  height: 100vw;
}

.item-details-product-label {
  line-height: normal;
}

.route-item-details .okx-pullup-content {
  padding-bottom: 20px;
}

.route-item-details .native-textarea.sc-ion-textarea-md,
.route-item-details .native-textarea.sc-ion-textarea-ios {
  transform: none !important;
  -webkit-transform: none !important;
}
.sub-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--ion-color-light);
  padding: 5px 0;
}
.sub-item ion-item {
  flex: 0 0 1;
}
.item-description {
  display: block;
  margin-top: 4px;
}
.price-increment-content p {
  text-align: end;
  margin: 0;
  margin-bottom: 5px;
}

@supports (-webkit-overflow-scrolling: touch) {
  /* iOS */
  .item-details-image {
    position: absolute;
  }
  .item-details-back {
    position: absolute;
  }
}

.item-details-header {
  display: flex;
  padding: var(--ion-padding) var(--ion-padding) 15px;
  position: absolute;
  top: 0;
  z-index: 5;
  width: 100%;
}
.item-details-price {
  padding: 0 var(--ion-padding);
}
.item-details-header.active {
  background-color: var(--ion-color-white);
}
.item-details-header ion-button {
  margin: 0;
}

.item-details-img {
  height: 350px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.item-details-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.image-placeholder {
  background: var(--ion-color-gray) url(/static/media/logo-main.c2db7137.png) no-repeat 50% 50%/90%;
}
.web .image-placeholder {
  height: 50px;
}
.item-details-description {
  flex: 1 1;
  display: flex;
  align-items: center;
  padding: 10px;
}

.item-details-info .item-details-name {
  padding: 20px var(--ion-padding) 0;
}

.item-details-main-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
/* 
.sub-item ion-item {
  flex: 0 0 220px;
} */
.special-instructions-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.special-instructions-modal-button {
  display: flex;
  align-items: flex-end;
  flex: 1 1;
}
.special-instructions-modal-button ion-button {
  width: 100%;
}
.special-instructions-modal .modal-classic-content {
  display: flex;
  flex-direction: column;
}
.web .special-instructions-modal .modal-classic-content {
  justify-content: flex-start !important;
}
.special-instructions-wrapper ion-icon {
  margin-left: 10px;
}
.special-instructions-wrapper ion-button {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
}

.add-instructions-button {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
}
.special-instructions-modal .modal-wrapper {
  border-radius: 0;
}
.web .special-instructions-modal .modal-wrapper {
  border-radius: var(--border-radius);
}
.special-instructions-modal {
  --height: 100% !important;
  --width: 100% !important;
}
.web .special-instructions-modal {
  --height: 80% !important;
  --width: 500px !important;
}

.special-instructions-wrapper .normal-text {
  white-space: pre-wrap;
}
@media screen and (max-width: 330px) {
  .item-details-card-content .item-details-card-list {
    padding: 0 10px;
  }
  .item-details-price {
    padding: 0 10px;
  }
}
.web .item-details-card-content {
  overflow-y: auto;
  padding: 10px 20px;
}

.allergens-title {
  font-size: 12px !important;
}

ion-content.route-delivery-options {
  width: 100%;
  height: calc(100vh - 79px);
  background-image: url(/static/media/refer-friend.eaf6079f.png);
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-y: hidden;
}

ion-content.route-delivery-options::before {
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 85%;
  z-index: 0;
  background-image: linear-gradient(rgba(000, 000, 000, 1), rgba(000, 000, 000, 0));
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.delivery-options-card {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  top: 50%;
  left: 50%;
  min-width: 280px;
  min-height: 260px;
  padding-top: 32px;
  padding-bottom: 25px;
  margin: 0;
  z-index: 2;
  --background: #fff;
  border-radius: var(--ion-card-radius);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.delivery-options-card ion-card-title {
  font-size: 25px;
  font-weight: bold;
}

.delivery-options-menu {
  padding: 0 24px;
}

.delivery-options-menu ion-item {
  --ion-color-contrast: var(--ion-color-white) !important;
  --color: var(--ion-color-contrast);
  --background: var(--ion-color-secondary);
  --min-height: 32px;
  margin-bottom: 10px;
  border-radius: calc(var(--okx-small-radius) - 2px);
  cursor: pointer;
}

.delivery-options-menu ion-label {
  font-weight: normal;
  text-align: center;
}

.delivery-options-menu > ion-list > ion-item > ion-label {
  padding-top: 2px;
  margin-right: 0;
  font-weight: bold !important;
}

.web #main > ion-content.route-delivery-options {
  background-size: cover;
}

.web ion-content.route-delivery-options::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: -1;
}

.web .delivery-options-card {
  left: 50%;
  right: auto;
  width: var(--okx-bsmodal-width-sm);
}

.delivery-options-description {
  font-weight: 100;
  opacity: 0.5;
}

.web ion-content.route-delivery {
  --ion-color-base: transparent;
  --background: transparent;
}

/* work */
ion-content div.restaurant-gradient {
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: cover;
}

ion-content div.restaurant-gradient::before {
  content: '';
  display: block;
  height: 80%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 85%;
  z-index: 3;
  background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.restaurant-card {
  border-radius: var(--ion-card-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  margin: 0;
}

.restaurant-card ion-card-content {
  padding: 30px;
  font-size: 11px;
}

.restaurant-card ion-card-content .restaurant-info-title {
  padding: 0 2px;
  font-weight: bold;
}

.restaurant-card ion-card-content .restaurant-info {
  padding: 15px 15px 30px;
  text-align: center;
}

.restaurant-card .flex-col-wrapper > .flex-min {
  display: flex;
  align-items: center;
}

.click-collect-pickers-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.3;
  height: 100%;
  width: 100%;
  z-index: 3;
}

.click-collect-dialog {
  position: fixed;
  margin: 0 auto;
  background-color: #fff;
  width: 70%;
  height: 50%;
  border-radius: 10px;
  top: 55%;
  left: 50%;
  -webkit-transform: translate(-50%, -45%);
          transform: translate(-50%, -45%);
  transition: all 0.3s ease-out;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 999;
}

.click-collect-dialog.show-up {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  pointer-events: auto;
}

.click-collect-dialog-layout {
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 5px 13px;
}

.click-collect-dialog.sc-ion-modal-md {
  border-radius: var(--border-radius);
  width: var(--width);
  min-width: var(--min-width);
  max-width: var(--max-width);
  height: var(--height);
  min-height: var(--min-height);
  max-height: var(--max-height);
  border-width: var(--border-width);
  border-style: var(--border-style);
  border-color: var(--border-color);
  background: var(--background);
  box-shadow: var(--box-shadow);
  overflow: var(--overflow);
  z-index: 10;
}

.click-collect-dialog-header {
  flex: 0 1 auto;
  padding: 10px 5px;
}

.click-collect-dialog-header h3 {
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.click-collect-dialog-closer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
}

.click-collect-dialog-closer ion-icon {
  font-size: 20px;
}

.click-collect-dialog-content {
  flex: 1 1 auto;
  padding: 12px;
  overflow-y: auto;
}

.click-collect-dialog-action {
  flex: 0 1 auto;
}

.click-collect-dialog-action ion-button {
  height: 36px;
}

.click-collect-dialog-content ion-label .sectiontitle {
  margin: 0;
  line-height: 18px;
}

#main > ion-content.route-click-and-collect .select-picker-label,
#main > ion-content.route-click-and-collect .time-picker-label {
  color: var(--ion-color-primary);
  font-size: 12px;
  font-family: var(--ion-default-font-bold);
  font-weight: bold;
}

#main > ion-content.route-click-and-collect .mbsc-select-input.mbsc-control {
  border-color: var(--ion-color-light);
}

.delivery-option-label {
  position: absolute;
  top: 30px;
  left: 64px;
  right: 64px;
  text-align: center;
  z-index: 3;
}

.web ion-content.route-click-and-collect {
  background-size: 100% auto;
  background-position: 100% 0;
  background-repeat: no-repeat;
}

.web ion-content.route-click-and-collect .ion-padding {
  padding-top: 0px;
}

.web .restaurant-card {
  top: 50%;
  bottom: auto;
  left: 50%;
  right: auto;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: var(--okx-bsmodal-width-lg);
  max-width: var(--okx-bsmodal-width-lg);
  min-width: 280px;
  padding: 150px 64px 64px;
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--okx-box-radius);
  background-color: var(--ion-color-white);
}

.web .restaurant-menu ion-item {
  --color: var(--ion-color-white);
  --background: var(--ion-color-black);
  margin-bottom: 20px;
  border-radius: var(--okx-small-radius);
}

.web .restaurant-card ion-card-content {
  padding: 0;
}

.web .restaurant-card ion-card-content .restaurant-info {
  padding: 15px 0 0;
}

.web .click-collect-dialog {
  width: var(--okx-bsmodal-width-lg);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.web .click-collect-dialog-layout {
  padding: 32px 64px 48px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.web .mbsc-select-input.mbsc-control {
  border-color: var(--ion-color-white);
}

.web .click-collect-dialog-header h3 {
  font-size: 22px;
  text-transform: uppercase;
}

.web .mbsc-fr-popup {
  left: 50%;
}

.web #main > ion-content.route-click-and-collect .delivery-option-label {
  display: none;
}

.web #main > ion-content.route-click-and-collect .delivery-option-label.web-only {
  display: block;
  top: 64px;
}

.click-collect-wrapper {
  height: 100%;
}

.click-collect-selectors {
  margin: 50px 0 40px 0;
}

.click-collect-title {
  margin-bottom: 5px !important;
}
#main > ion-content.route-click-and-collect .mbsc-select-input.mbsc-control {
  display: none;
}

.click-collect-layout {
  height: 100%;
  padding-bottom: 35px;
}

.no-padding {
  height: 100%;
}

.web .click-collect-wrapper {
  margin: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.click-collect-button {
  position: fixed;
  bottom: 0px;
  padding-bottom: 24px;
  width: auto;
  left: 20px;
  right: 20px;
  background-color: var(--ion-color-primary-contrast);
}
.web .click-collect-button {
  position: absolute ;
}

.route-click-and-collect .mbsc-ios .mbsc-sc-itm-sel,
.route-click-and-collect .mbsc-ios .mbsc-fr-btn {
  color: var(--ion-color-secondary) !important;
  font-weight: normal;
}
.store-is-open {
  color: var(--ion-color-secondary) !important;
}
.store-is-closed {
  color: var(--ion-color-secondary) !important;
}

.click-collect-items ion-label{
  display: grid !important;
  margin-bottom: 10px !important;
}
.address-checking-box {
  min-height: 80px;
}

.delivery-address-bg {
  background-image: url(/static/media/Click&Collect.f1d29dc9.jpg);
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: cover;
}

.delivery-address-bg::before {
  content: attr(title);
  position: absolute;
  font-size: var(--okx-title-font-size);
  font-weight: bold;
  text-align: center;
  padding-top: 20px;
  display: block;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  height: 50%;
  z-index: 2;
  background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.route-delivery-address-check .restaurant-card {
  top: 0;
}

.route-delivery-address-check .restaurant-card ion-card-content {
  height: 100%;
}

/* #main > ion-content.route-delivery-address-check .restaurant-card {
	position: relative;
	height: calc(100vh - 114px);
	border-radius: 0;
} */

/* #main > ion-content.route-delivery-address-check .restaurant-card ion-card-content {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0; left: 0; right: 0; bottom: 0;
	z-index: -1;
} */

/* #main > ion-content.route-delivery-address-check .no-padding .restaurant-card ion-card-content .button-holder {
	flex: 1 1 auto;
	display: flex;
	align-items: flex-end;
}

#main > ion-content.route-delivery-address-check .no-padding .restaurant-card ion-card-content .button-holder ion-button {
	flex: 1 1 auto;
}


#main > ion-content.route-delivery-address-check .no-padding .restaurant-card ion-card-content .underlined {
	font-size: var(--okx-small-text-font-size);
}

#main > ion-content.route-delivery-address-check .no-padding ion-text:last-child > span {
	letter-spacing: .044em;
} */

.web .route-delivery-address-check .restaurant-card {
  top: 50%;
}

.web .delivery-address-bg::before {
  content: '';
}

.web #main > ion-content.route-delivery-address-check .delivery-option-label {
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  text-align: center;
}

/* .delivery-address-add > div.scrollable-y > ion-list > ion-item > ion-input { */
/* direction: LTR;
  unicode-bidi: bidi-override; */
/* writing-mode: vertical-rl;
  text-orientation: upright; */
/* } */

/* .delivery-address-add > div.scrollable-y > ion-list > ion-item > ion-input > input.native-input.sc-ion-input-ios:invalid { */
/* direction: ltr;
  unicode-bidi: bidi-override; */
/* writing-mode: vertical-rl;
  text-orientation: upright; */
/* } */

ion-content.route-delivery-address-add
  ion-textarea
  > div
  > textarea.native-textarea.sc-ion-textarea-ios {
  padding: 0px;
  padding-top: 7px;
}

ion-content.route-delivery-address-add ion-item {
  --border-color: var(--ion-color-light);
}

.web ion-content.route-delivery-address-add {
  --ion-color-base: transparent;
  --background: transparent;
}

.web ion-content.route-delivery-time {
	--ion-color-base: transparent;
	--background: transparent;
}

.web .route-delivery-time .no-padding {
	height: 500px;
}

.map-inline > div:first-child {
	height: 100%;
}

ion-modal.modal-classic.pup-modal {
	--width: 80%;
	--height: 80%;
}

.pup-map-wrapper {
	position: relative;
	margin: 20px auto;
	width: 80%;
	min-width: 200px;
}

.pup-map-wrapper::after {
	content: '';
	display: block;
	padding-top: 100%;
}

.code-checking-box {
  min-height: 80px;
}

.web ion-content.route-pick-up-point-check .restaurant-card {
  padding-top: 64px;
}

.route-cards .ion-padding .absolute-content .link {
  font-size: 11px !important;
  /* font-weight: bold; */
  text-transform: none;
  text-transform: initial;
}

.web ion-content.route-cards {
  --background: transparent none;
  --ion-color-white: transparent;
}

.web .route-cards .ion-padding {
  position: absolute;
  left: 50%;
  top: 30px;
  bottom: 50px;
  width: 400px;
  margin-left: -200px;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web .route-cards .ion-padding .absolute-content {
  padding: 32px;
}

.web .route-cards .ion-padding .absolute-content .scrollable-y/*,
.web .route-cards .ion-padding .absolute-content .flex-min*/ {
  padding: 0 32px;
}

.web .route-cards .ion-padding ion-button:last-child {
  margin-bottom: 0;
}

.payment-card-input {
  border: 0;
  outline: none;
  flex: 1 1;
  padding-left: 0;
}

.scrollable-y.add-card-scroll {
  overflow: unset;
  overflow-y: unset;
}

.web-stripe-input {
  min-height: 48px;
  display: flex;
  flex: 1 1;
  align-items: center;
  border-bottom: 1px solid
    rgba(0, 0, 0, 0.13);
  border-bottom: 1px solid
    var(
      --ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
    );
}

.web-stripe-input > .__PrivateStripeElement {
  width: 100%;
}

ion-list .card-list ion-item {
  padding-left: 7px;
}

.web-stripe-input-active {
  border-bottom: 2px solid var(--ion-color-primary);
}

.card-add-wrapper .data-picker-input {
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.13);
  border-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
  );
}

.cards .ion-text-wrap > ion-note {
  color: var(--ion-color-primary);
  font-size: 10px;
}

.route-card-add .ion-padding .absolute-content ion-list ion-item .sectiontitle {
  margin: 4px 0;
}

.cards ion-item {
  border-bottom: 1px solid var(--ion-color-light);
}

.cards + ion-button.link {
  font-size: var(--okx-small-text-font-size);
}

.web .scrollable-y.add-card-scroll {
  overflow: hidden;
  overflow-y: auto;
}

.web ion-content.route-card-add {
  --background: transparent none;
  --ion-color-white: transparent;
}

.web .route-card-add .ion-padding {
  position: absolute;
  left: 50%;
  top: 30px;
  bottom: 50px;
  width: var(--okx-bsmodal-width-lg);
  margin-left: -200px;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web .route-card-add .ion-padding .absolute-content {
  padding: 32px;
}

.web .route-card-add .ion-padding .absolute-content .scrollable-y/*,
.web .route-card-add .ion-padding .absolute-content .flex-min*/ {
  padding: 0 32px;
}

.web .route-card-add .ion-padding ion-button:first-child {
  display: none;
}

.web .route-card-add .ion-padding ion-button:last-child {
  margin-bottom: 0;
}

.apple-pay-button {
  display: inline-block;
  background-size: 100% 60%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 5px;
  padding: 0px;
  box-sizing: border-box;
  width: 100vh;
  height: 60%;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: check-out;
}

.apple-pay-button-black {
  -apple-pay-button-style: black;
}

.apple-pay-button-white {
  -apple-pay-button-style: white;
}

.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
}

.apple-button {
  --background-hover-opacity: 0;
}

/* .google-button {
	background-image: url(../../assets/images/google_pay_black.png);
	--background: transparent none;
	background-repeat: no-repeat;
	background-position: 50% 40%;
	border-radius: 5px;
} */

.google-pay-btn {
  width: 100%;
  padding: 0 2px;
  margin-bottom: 10px;
}

.google-pay-btn > .gpay-button {
  width: 100% !important;
}

.google-pay-btn .gpay-card-info-container {
  width: 100% !important;
}
.google-pay-btn button {
  min-width: 100% !important ;
}

.gpay-card-info-animation-gpay-logo {
  margin: auto !important;
}

.apply-points-spacer {
  margin: 32px 0;
  height: 170px;
}

.route-apply-points .ion-padding .incrementer-quantity-solo {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.route-apply-points .ion-padding .incrementer-quantity-solo .incrementer-quantity-value {
  font-size: var(--okx-big-label-font-size);
}

.route-apply-points .ion-padding .incrementer-decrease,
.route-apply-points .ion-padding .incrementer-incease {
  --border-color: var(--ion-color-secondary);
}

.web .route-apply-points {
  --background: transparent none;
  --ion-color-white: transparent;
}

.web .route-apply-points .ion-padding {
  position: absolute;
  left: 50%;
  top: 30px;
  bottom: 50px;
  width: 400px;
  margin-left: -200px;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web .route-apply-points .ion-padding .absolute-content {
  padding: 32px 32px 16px;
}

.web .route-apply-points .ion-padding .absolute-content .scrollable-y/*,
.web .route-apply-points .ion-padding .absolute-content .flex-min*/ {
  padding: 0 32px;
}

.web ion-modal.modal-classic.apply-points-modal .modal-classic-closer {
  font-size: 20px;
  padding: 11px;
}

.web ion-modal.modal-classic.apply-points-modal .incrementer-decrease,
.web ion-modal.modal-classic.apply-points-modal .incrementer-incease {
  --border-color: var(--ion-color-secondary);
}

.order-intro-text ion-icon {
  color: var(--ion-color-secondary) !important;
}
.table-number {
  color: var(--ion-color-secondary) !important ;
}
.change-table-number-field {
  border-bottom: 1px solid;
  text-align: center;
}
.confirm-button {
  margin-top: 30px;
}
.change-table-number-modal-true {
  background-color: rgb(255 255 255 / 43%);
}
.change-table-number-modal-true .modal-wrapper {
  height: 180px !important;
  width: 200px !important;
}

.validate-content {
  margin-top: 50px;
}

ion-item.contact-number {
  --border-color: var(--ion-color-light);
}

ion-item.contact-number > ion-input {
  --padding-bottom: 6px !important;
  font-size: 14px;
  margin-left: 10px;
}

.web ion-item.contact-number > ion-input {
  margin-left: 0px;
}

.link.link-add-items {
  background: var(--ion-color-primary);
  padding-left: var(--okx-box-padding-left);
  padding-right: var(--okx-box-padding-right);
  border-radius: 30px;
}

.checkout > .righted > ion-button.link.link-centered {
  background: black;
  color: white;
  border-radius: 20px;
  min-width: 110px;
}

.checkout + .flex-min > ion-button.link {
  text-decoration: underline;
}

.checkout + .flex-min > ion-button.link + ion-button > ion-icon {
  font-size: 15px;
}

.checkout .order-content-wrapper .basket-item-wrapper > ion-row > ion-col,
.checkout .order-content-wrapper .basket-item-wrapper + ion-row > ion-col > ion-text > span,
.checkout .order-content-wrapper .basket-item-wrapper + ion-row > ion-col {
  font-family: var(--ion-default-font-thiner);
  font-size: 11px;
  font-weight: normal;
  font-weight: initial;
}

.apply-points-modal > .modal-wrapper {
  width: 100%;
  height: 100vh;
  border-radius: 0;
  overflow: hidden;
}

.apply-points-modal .incrementer {
  justify-content: space-between;
  padding: 0 10px;
}

.apply-points-modal .incrementer .incrementer-quantity-solo {
  flex: 0 0 auto;
  font-weight: bold;
}

.apply-points-modal .wrap-small-text {
  display: flex;
  justify-content: center;
}

.web .route-order-summary {
  --background: transparent none;
  --ion-color-white: transparent;
}

/* .web .static.route-order-summary {
background-image: url(../../assets/images/new-dashboard-01.jpg);
background-repeat: no-repeat;
background-position: 50% 0;
background-size: 100% auto;
}

.web ion-content.route-order-summary {
background-image: url(../../assets/images/new-dashboard-01.jpg);
background-repeat: no-repeat;
background-position: 50% -113px;
background-size: 100% auto;
} */

ion-app:not(.web) .allergen-modal > .modal-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

ion-app:not(.web) .allergen-modal > .modal-wrapper > .ion-page {
  min-width: 100%;
  overflow: auto;
}

ion-app:not(.web) .allergen-modal > .modal-wrapper > .ion-page > .modal-classic-wrapper {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

ion-app:not(.web) .allergen-modal > .modal-wrapper > .ion-page .sectiontitle {
  font-size: 11px;
  letter-spacing: 0.044em;
}

.web .apply-points-modal > .modal-wrapper {
  width: var(--okx-sidebar-width);
}

.web ion-modal.modal-classic.allergen-modal .modal-classic-closer {
  font-size: 20px;
  padding: 11px;
}

.contact-number-label {
  padding-top: 3px;
}

.web .route-order-summary .ion-padding {
  position: absolute;
  width: var(--okx-bsmodal-width-lg);
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  top: 0 !important;
  left: 0  !important;
  bottom: 0  !important;
  right: 0  !important;
  margin: auto  !important;
  height: 80%;
}

.web .route-order-summary .ion-padding .absolute-content {
  padding: 32px;
}

.web .route-order-summary .ion-padding .absolute-content .scrollable-y/*,
.web .route-order-summary .ion-padding .absolute-content .flex-min*/ {
  padding: 0 32px;
}

.web .route-order-summary .checkout {
  margin-bottom: 20px;
}

.web .route-order-summary .ion-padding ion-button:last-child {
  margin-bottom: 0;
}

.web .route-order-summary .ion-padding ion-button.add-items-btn,
.web .route-order-summary .ion-padding ion-button.redeem-points-btn {
  margin: 10px 0 !important;
}

.web .route-order-summary .ion-padding ion-button.redeem-points-btn.disabled,
.web .route-order .no-padding ion-button.redeem-points-btn.disabled {
  --ion-color-dark: gray;
}

.contact-number-icon {
  position: absolute;
  right: 0;
  bottom: 2px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 50%;
  padding: 7px;
  transition: 0.2s ease-in-out;
}

.web .contact-number-icon {
  bottom: 4px;
}

.web .contact-number-icon:hover {
  background: rgba(0, 0, 0, 0.13);
}

ion-button.checkout-btn.greyed {
  /* --background: var(--ion-color-gray-tint); */
  /* --background: var(--ion-color-secondary); */
  color: var(--ion-color-secondary);
}

.allergen-info ion-button.button-outline {
  border: 1px solid var(--ion-color-primary) !important;
}
.StripeElement {
  height: 39px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
  margin-top: 10px;
  margin-bottom: 10px;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}
.StripeElement iframe{
  height: 39px;
  border-radius: var(--okx-small-radius);
}
ion-button.link.link-centered {
  margin: 0 auto;
}

.web ion-content.route-checkout {
  --background: transparent;
  --ion-color-white: transparent;
}

.web .route-checkout .ion-padding {
  position: absolute;
  left: 50%;
  top: 30px;
  bottom: 50px;
  width: var(--okx-bsmodal-width-lg);
  margin-left: -200px;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web .route-checkout .ion-padding .absolute-content {
  padding: 32px 32px 16px;
}

.web .route-checkout .ion-padding .absolute-content .scrollable-y/*,
.web .route-checkout .ion-padding .absolute-content .flex-min*/ {
  padding: 0 32px;
}

.paymentRequestButton {
  margin-top: 20px;
  padding: 0 2px;
}

.paymentRequestButtonCardAdd {
  margin-bottom: 20px;
  padding: 0 2px;
  --border-radius: var(--okx-small-radius);
}

.payrequest-btn {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  --background: #25b7c7;
  height: 39px;
  --padding-top: 8px;
  --padding-bottom: 8px;
  --padding-start: 16px;
  --padding-end: 16px;
  --border-radius: var(--okx-small-radius);
  border: none;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  line-height: 1.7;
}

.payrequest-btn img {
  height: 23px;
}
/* .order-details {
	background: transparent url(../../assets/images/order-completed-bgd.jpg) no-repeat 100% 125%;
	background-size: 100% auto;
	z-index: 1;
} */

.order-status {
  width: auto;
  display: inline-block;
  font-family: var(--ion-default-font);
  font-size: var(--okx-small-text-font-size);
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid var(--ion-color-primary);
  padding: 2px 8px;
  margin-bottom: 5px;
  line-height: 1;
}

.order-details::after {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  content: '';
  background-image: linear-gradient(#fff 82%, rgba(255, 255, 255, 0) 115%);
}

.order-details ion-grid {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
}

.order-details ion-col {
  font-weight: normal;
}

.order-details .okx-box-footer {
  border-top: 2px solid var(--okx-border-color);
  padding-left: 0;
  margin-left: 0;
  padding-right: 0;
  margin-right: 0;
}

.order-details .okx-box-footer ion-row {
  border-bottom: 0 none;
}

.order-details .okx-box-header-grid ion-row:nth-of-type(1n) {
  border: 0;
  opacity: 0.8;
}

.order-details .okx-box-header-grid ion-row {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  height: 18px;
}

.order-details .okx-box-header-grid ion-row ion-col:nth-of-type(2) {
  display: flex;
  justify-content: flex-end;
  opacity: 0.8;
  padding: 2px;
}

.order-done-button{
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.route-order-completed .scrollable-y{
  height: 100%;
}

.order-details .okx-box-header-grid ion-row ion-col:nth-of-type(1) ion-text {
  white-space: nowrap;
}

.web ion-content.route-order-completed {
  --background: transparent none;
  --ion-color-white: transparent;
}

.web ion-content.route-order-completed {
  background-color: #fff;
  background-image: url(/static/media/refer-friend.eaf6079f.png);
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.web #main > ion-content.route-order-completed > .ion-padding {
  position: absolute;
  left: 50%;
  top: 50%;
  /* bottom: 50px; */
  width: var(--okx-bsmodal-width-lg);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-left: 0;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  padding: 64px;
  min-height: 75%;
}

.web #main > ion-content.route-order-completed > .ion-padding > .absolute-content.order-details {
  background: none;
  overflow-y: auto;
}

.web
  #main
  > ion-content.route-order-completed
  > .ion-padding
  > .absolute-content.order-details:after {
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web
  #main
  > ion-content.route-order-completed
  > .ion-padding
  > .absolute-content.order-details
  > div.scrollable-y {
  height: 100%;
}

.web .order-details .okx-box,
.web .order-details .okx-box-header {
  border-color: var(--okx-border-color);
  border-width: 1px;
}

.web .order-details .okx-box-header-grid ion-row:not(:last-child) ion-col {
  /* padding: 7px 5px 0 5px; */
}

.web .order-details .okx-box-header-grid ion-row:first-of-type ion-col {
  margin-top: 5px;
}

.web .order-details .okx-box-header-grid ion-row:last-of-type ion-col {
  padding-bottom: 7px;
}

.web .order-details .okx-box-header-grid ion-row ion-col:nth-of-type(odd) strong {
  font-weight: normal;
}

.web .order-details .okx-box-header-grid ion-row ion-col:nth-of-type(even) {
  text-align: right;
  font-weight: bold;
}

@media screen and (min-width: 1441px) {
  .web #main > ion-content.route-history-details > .ion-padding {
    min-height: 65%;
  }
}

.mb-6 {
  margin-bottom: 6px;
}
ion-content.route-contact-details .inline-input > ion-text,
ion-content.route-contact-details .inline-input > label {
  flex: 0 0 35%;
  /* margin-right: 50px; */
}

.web ion-content.route-contact-details {
  --background: transparent;
  --ion-color-white: transparent;
}

.bottom-button {
  position: fixed !important;
  bottom: 0px;
  width: 100%;
}

.create-account-modal ion-content {
  background-image: none !important;
}
.guest-terms-and-conditions {
  border: 1px solid;
  font-size: 10px;
  padding: 10px;
  border-radius: 10px;
}
.guest-terms-and-conditions-title {
  font-size: 20px !important;
}
.create-account-modal {
  --height: 70% !important;
}
.create-account-modal .modal-wrapper {
  border-radius: 20px !important;
}
.create-account-modal .ion-page {
  overflow: unset !important;
}
.create-account-modal .modal-classic-content {
  overflow-y: unset !important;
  padding: var(--ion-padding) !important;
}
.modal-is-true {
  background-color: rgb(255 255 255 / 43%);
}
.create-account-modal .toolbar-title-default {
  display: none;
}
.create-account-modal .route-contact-details {
  --overflow: unset;
}
.create-account-modal .modal-classic-closer {
  display: none;
}
.inner-scroll {
  background-color: black !important;
}
@media screen and (max-height: 580px) {
  .create-account-modal {
    --height: 80% !important;
  }
  .contact-details {
    padding-top: 0px !important;
  }
}

.toggle {
  display: flex;
  flex-direction: column;
}
.web #main > ion-content.route-register {
  z-index: 5;
}
.create-account {
  padding-top: 0px !important;
  padding-bottom: 100px !important;
}
.create-account-title {
  text-align: center;
  font-size: 40px !important;
}
.create-account-text {
  font-family: var(--ion-default-font-thiner);
  font-size: 10px !important;
}
.create-account-text-points {
  color: var(--ion-color-secondary) !important;
}
.create-account-buttons-div {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
}
@media screen and (max-width: 350px) {
  .create-account-title {
    font-size: 35px !important;
  }
}

.table-number-input {
  --padding-start: 0;
  border-bottom: 1px solid;
  border-bottom-color: var(--okx-border-color);
}

#main > ion-content.route-order-to-table .select-picker-label,
#main > ion-content.route-order-to-table .time-picker-label {
  color: var(--ion-color-primary);
  font-size: 12px;
  font-family: var(--ion-default-font-bold);
  font-weight: bold;
}

.web ion-content.route-order-to-table {
  background-size: 100% auto;
  background-position: 100% 0;
  background-repeat: no-repeat;
}

.table-number-input {
  --padding-start: 0;
  border-bottom: 1px solid rgba(var(--ion-color-gray-rgb), 0.6);
  margin-left: 30px;
}

.table-number ion-text {
  margin: auto auto;
}

/* Web */

.web #main > ion-content.route-order-to-table > .no-padding > .absolute-content {
  background: var(--okx-app-bgd-color);
}

.diffrent_location_div {
  margin-top: 20px;
  border-top: 1px solid rgba(var(--ion-color-gray-rgb), 0.6);
}

.diffrent_location_div > ion-button {
  font-size: calc(var(--okx-small-text-font-size)) !important;
}

.click-collect-layout .table-number {
  display: flex;
}

.web ion-content.route-order-to-table .ion-padding {
  overflow-y: auto;
}

.click-collect-button {
  background-color: transparent;
}

@media screen and (max-height: 730px) {
  .web ion-content.route-order-to-table .ion-padding {
    top: 15% !important;
  }
}

ion-modal.modal-classic.apply-voucher-modal {
  --width: 80%;
  --height: 45%;
  --border-radius: 10px;
}

.web ion-modal.modal-classic.apply-voucher-modal {
  --width: 500px;
  --height: 45%;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-wrapper {
  padding: 0;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-header {
  background-color: var(--ion-color-secondary);
  padding-left: 20px;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-header h3 {
  color: var(--ion-color-white);
  text-transform: uppercase;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-closer {
  color: var(--ion-color-white);
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-content {
  padding: 15px 15px;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-content > .flex-row-wrapper {
  height: 100%;
}

.apply-voucher-modal.voucher-modal-content {
  padding: 0 30px;
}

.voucher-item ion-item ion-input {
  opacity: 0.8;
}

ion-button.box-button-color {
  --background: var(--ion-color-gray-tint);
}

ion-button.box-button-color-tertiary {
  --background: var(--ion-color-tertiary);
}

.apply-voucher {
  margin-bottom: 20px;
  border: 2px solid var(--ion-color-primary);
}

.web .apply-voucher {
  margin-bottom: 20px;
  border: 2px solid var(--ion-color-primary);
}

.web .apply-voucher,
.web .apply-voucher-box {
  cursor: pointer;
}

.web ion-content.route-apply-vouchers {
  --background: transparent none;
  --ion-color-white: transparent;
}

.payment-card-input {
  border: 0;
  outline: none;
  flex: 1 1;
  padding-left: 0;
}

.scrollable-y.add-card-scroll {
  overflow: unset;
  overflow-y: unset;
}

.web-stripe-input {
  min-height: 48px;
  display: flex;
  flex: 1 1;
  align-items: center;
  border-bottom: 1px solid
    rgba(0, 0, 0, 0.13);
  border-bottom: 1px solid
    var(
      --ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
    );
}

.web-stripe-input > .__PrivateStripeElement {
  width: 100%;
}

ion-list .card-list ion-item {
  padding-left: 7px;
}

.web-stripe-input-active {
  border-bottom: 2px solid var(--ion-color-primary);
}

.card-add-wrapper .data-picker-input {
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.13);
  border-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
  );
}

.cards .ion-text-wrap > ion-note {
  color: var(--ion-color-primary);
  font-size: 10px;
}

.route-card-add .ion-padding .absolute-content ion-list ion-item .sectiontitle {
  margin: 4px 0;
}

.cards ion-item {
  border-bottom: 1px solid var(--ion-color-light);
}

.cards + ion-button.link {
  font-size: var(--okx-small-text-font-size);
}

.web .scrollable-y.add-card-scroll {
  overflow: hidden;
  overflow-y: auto;
}

.web ion-content.route-card-add {
  --background: transparent none;
  --ion-color-white: transparent;
}

.web .route-card-add .ion-padding {
  position: absolute;
  left: 50%;
  top: 30px;
  bottom: 50px;
  width: var(--okx-bsmodal-width-lg);
  margin-left: -200px;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web .route-card-add .ion-padding .absolute-content {
  padding: 32px;
}

.web .route-card-add .ion-padding .absolute-content .scrollable-y/*,
.web .route-card-add .ion-padding .absolute-content .flex-min*/ {
  padding: 0 32px;
}

.web .route-card-add .ion-padding ion-button:first-child {
  display: none;
}

.web .route-card-add .ion-padding ion-button:last-child {
  margin-bottom: 0;
}

.order-categories {
  flex: 0 0 auto;
  padding-bottom: 0px;
  background-color: var(--ion-color-primary-contrast);
}

.order-categories h2 {
  font-size: var(--okx-sectiontitle-font-size);
  padding: 0 25px;
  margin: 15px 0;
}

.order-categories-inner {
  display: flex;
  flex-flow: row;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  overflow-y: auto;
}

.order-content {
  flex: 1 1 100%;
  display: flex;
  flex-flow: column;
  padding: 0 25px;
  overflow: hidden;
}

.category-button {
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-primary);
  --color: var(--ion-color-black);
  font-family: var(--ion-font-family);
  font-size: 10px;
  font-weight: bold;
  --border-radius: 14px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
}

.category-button:first-child {
  margin-left: 0;
}

div.order-categories-inner > ion-button:first-child {
  padding-left: 25px;
}

.web div.order-categories-inner > ion-button:first-child {
  padding-left: 0px;
}

.category-button.active {
  --background: var(--ion-color-secondary);
  --border-width: 0px;
  --border-style: unset;
  --border-color: transparent;
  --color: #fff;
  --background-activated: var(--ion-color-secondary);
}

#main > ion-content.route-order .list-ios .item-lines-full {
  --border-color: var(--ion-color-light);
}

.order-list-items {
  flex: 0 1 100%;
  overflow-y: auto;
  /* height: 100%; */
  /* padding-bottom: 70px; */
}

.order-list-items > .list-md {
  padding-bottom: 0;
}

.order-list-items ion-item {
  --min-height: 20px;
  --inner-padding-end: 0;
  cursor: pointer;
}

.order-list-item {
  display: flex;
  align-items: baseline;
  width: 100%;
  margin-bottom: 15px;
}
.order-list-item > div {
  flex: 0 1 auto;
  margin-left: 10px;
}
.order-list-item > div:first-child {
  margin-left: 0;
  flex: 0 0 50px;
  display: flex;
  justify-content: flex-end;
}
.order-list-item > div:nth-child(2) {
  flex: 1 1 auto;
}
.order-list-item > div:nth-child(3) {
  align-self: flex-start;
  text-align: right;
}
.item-content {
  font-size: 0.8em;
}
.item-content > h5 {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: var(--ion-color-dark);
  letter-spacing: 0.044em;
}

.item-content > div {
  color: var(--ion-color-primary);
  font-family: var(--ion-default-font-thiner);
  font-size: 11px;
  opacity: 0.8;
  letter-spacing: 0.2px;
}

.item-content p {
  color: var(--ion-color-gray);
  margin: 0;
  margin-top: 5px;
}
.item-image {
  border-radius: 5px;
  background: none transparent no-repeat 50% 50%;
  background-size: 100% auto;
  width: 50px;
  padding-top: 100%;
}
.item-price > span:first-child {
  color: var(--ion-color-secondary);
  font-size: 14px;
  font-weight: bold;
}
.item-price > span.original-price {
  text-decoration: line-through;
  font-size: 0.9em;
}
.order-list-header {
  padding: 25px;
  margin-bottom: 10px;
}

.order-list-header ion-label {
  font-size: 25px;
  font-weight: 600;
  text-transform: none;
  text-transform: initial;
}

.order-sublist-header {
  padding-left: 0;
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  background-color: white;
  z-index: 2;
  --color: var(--ion-color-dark);
}

.order-sublist-header.empty {
  /* max-height: 0;
	height: 0px;
	overflow: hidden;
	min-height: 0; */
  display: none;
}

.order-sublist-header ion-label {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.order-intro-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: var(--ion-color-light-shade);
}

.order-intro-image-wrapper {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-black);
  background: var(--ion-color-light-shade);
  width: 100%;
}

.order-intro-content {
  height: 250px;
  padding: 25px;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  background: var(--ion-color-medium-contrast);
}

.order-intro-content p {
  color: var(--ion-color-black);
  text-align: center;
  padding: 0 10px;
  font-size: 14px;
  margin: 25px 0;
}

.route-order > .no-padding > .segment-holder > .order-categories > ion-text > span {
  width: calc(100vw - 40px);
  box-sizing: inherit;
  padding: 0 25px 20px 25px;
  float: left;
  font-size: 10px;
  letter-spacing: 0.2px;
}

.route-order > .no-padding > .segment-holder > .order-categories > ion-text > span > .single-item {
  margin-bottom: 5px;
}

.order-intro-modal > .modal-wrapper {
  height: 320px;
  width: 270px;
  border-radius: 15px;
}

.order-intro-modal > .modal-wrapper > .ion-page {
  min-width: 100%;
  padding: 15px 25px;
}

.order-intro-modal > .modal-wrapper > .ion-page ion-icon {
  position: absolute;
  right: 15px;
  font-size: 35px;
}

.order-intro-modal > .modal-wrapper > .ion-page h2 {
  font-size: 20px;
  margin-top: 4px;
}

.order-intro-modal > .modal-wrapper > .ion-page .order-intro-modal-inner {
  flex: 1 1;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}

.location-select {
  --padding-start: 0;
  margin-top: 5px;
  border-bottom: 1px solid var(--ion-color-medium);
}

.popover-content ion-item ion-label {
  margin-left: 10px;
}

.pickup-time {
  margin-top: 5px;
  border-bottom: 1px solid var(--ion-color-medium);
}

.item-content {
  margin-right: auto;
}

.item-image {
  margin-left: auto;
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
}
.app {
  display: grid;
  grid-template-columns: 20% 80%;
}
.navbar {
  padding: 0.5rem;
}
.navbar .nav-link {
  padding: 0.5rem;
}
.header {
  height: 80px;
  overflow: auto;
  background: #aaa;
}
.container {
  height: 500px;
  background: #ddd;
}
.gap {
  height: 500px;
}
.gap.short {
  height: 250px;
}
.gap.tall {
  height: 1000px;
}
.container.relative {
  overflow-y: auto;
}

.view-order-button {
  position: fixed;
  bottom: 0px;
  font-family: var(--ion-font-family);
  text-align: center;
  z-index: 1000;
  width: auto;
  left: 0px;
  right: 0px;
}

.view-order-button ion-button {
  margin: 0 !important;
}

.order-list-with-button > ion-list > div:last-child > div > div:last-child > ion-item {
  padding-bottom: 40px;
}
.segment-holder.grid-menus {
  overflow: auto !important;
  padding-bottom: 40px;
}
.segment-holder.grid-menus .order-categories {
  display: flex;
  flex-direction: column;
}
.order-categories-tiles-wrapper {
  width: 100%;
}
.order-categories-two-columns-tiles-wrapper {
  width: 50%;
}
.order-categories-tile {
  height: 150px;
  width: calc(100% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  margin: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: darken;
  text-align: center;
  padding: 20px;
}
.grid-menus .order-categories {
  padding: 0;
}
.route-order > .no-padding > .segment-holder.grid-menus > .order-categories > ion-text > span,
.grid-menus .title {
  padding-left: 30px;
  padding-bottom: 10px;
}
.grid-menus .order-categories-tile-title {
  color: var(--ion-color-primary-contrast);
  font-size: 22px;
  text-transform: uppercase;
}
.grid-menus .order-list-item {
  display: grid;
  margin: 0;
  grid-gap: 10px;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'image image'
    'desc desc'
    'price icon';
  align-items: flex-end;
  padding: 0;
}
.grid-menus .order-list-items ion-item {
  --border-style: unset;
  background-color: var(--ion-color-primary-contrast);
  margin: 10px;
  padding: 7px !important;
  width: calc(50% - 20px);
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}
.grid-menus .item-content {
  margin: 0;
  grid-area: desc;
}
.grid-menus .item-content > div {
  display: none;
}
.grid-menus .item-price {
  grid-area: price;
  margin: 0;
}
.grid-menus .item-icon-wrapper {
  grid-area: icon;
}
.grid-menus .item-icon-wrapper ion-icon {
  height: 20px;
  width: 20px;
  opacity: 0.4;
}
.grid-menus .item-price > span:first-child {
  font-size: 18px;
  font-weight: 400;
}
.grid-menus .default-height {
  display: none;
}
.grid-menus .item-content > h5 {
  font-size: 18px;
  font-weight: bolder;
  font-weight: 900;
  letter-spacing: normal;
  min-height: 50px;
  text-align: start;
}
.grid-menus .order-list-item > div:first-child {
  flex: unset;
}
.grid-menus .item-image-wrapper {
  width: 100%;
  margin: 0;
  grid-area: image;
}
.grid-menus .item-image {
  width: 100%;
  height: 150px;
  padding: 0;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.2);
  background-size: cover;
}

.grid-menus .category-title {
  margin: 10px 10px 10px 20px;
  font-size: 20px;
  font-weight: bold;
}
.grid-menus .order-sublist-holder {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.grid-menus .order-list-items ion-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
}

.view-order-button ion-button ion-grid {
  padding-bottom: 9px;
}

@media (max-width: 700px) {
  .app {
    grid-template-columns: unset;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  /* iOS */
  .order-list-items {
    height: calc(100vh - 300px);
  }
}

.order-categories {
  flex: 0 0 auto;
  padding: 0;
}

.order-categories h2 {
  padding: 0 25px;
}

.order-categories-inner {
  display: flex;
  flex-flow: row;
  width: 100%;
  overflow-y: auto;
}

.order-content {
  flex: 1 1 auto;
  padding: 0 25px;
  overflow: hidden;
}

.category-button {
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-gray);
  --color: var(--ion-color-black);
  font-weight: normal;
  --border-radius: 14px;
  text-transform: uppercase;
}

.category-button.active {
  --background: var(--ion-color-secondary);
  --border-width: 0px;
  --border-style: unset;
  --border-color: transparent;
  --color: #fff;
  --background-activated: var(--ion-color-secondary);
}

.web .order-list-items {
  overflow-y: auto;
  height: 100%;
  padding-right: 30px;
  /* padding-bottom: 70px; */
}

.item-content {
  font-size: 0.8em;
}
.item-content > h5 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  color: var(--ion-color-dark);
}
.item-content p {
  margin: 0;
}
.order-list-header {
  padding: 25px;
  margin-bottom: 10px;
}

.order-list-header ion-label {
  font-size: 25px;
  font-weight: 600;
  text-transform: none;
  text-transform: initial;
}

.order-sublist-header {
  padding-left: 10px;
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  background-color: white;
  z-index: 999;
  --color: var(--ion-color-dark);
}
.order-sublist-header ion-label {
  font-size: 15px;
  font-weight: 700;
  text-transform: none;
  text-transform: initial;
}

.order-sublist-header.empty {
  display: none;
}

.order-intro-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: var(--ion-color-light-shade);
}

.order-intro-image-wrapper {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-black);
  background: var(--ion-color-light-shade);
  width: 100%;
}

.order-intro-content {
  height: 250px;
  padding: 25px;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  background: var(--ion-color-medium-contrast);
}

.order-intro-content p {
  color: var(--ion-color-black);
  text-align: center;
  padding: 0 10px;
  font-size: 14px;
  margin: 25px 0;
}

.order-intro-modal > .modal-wrapper {
  height: 320px;
  width: 270px;
  border-radius: 15px;
}

.order-intro-modal > .modal-wrapper > .ion-page {
  min-width: 100%;
  padding: 15px 25px;
}

.order-intro-modal > .modal-wrapper > .ion-page ion-icon {
  position: absolute;
  right: 15px;
  font-size: 35px;
}

.order-intro-modal > .modal-wrapper > .ion-page h2 {
  font-size: 20px;
  margin-top: 4px;
}

.order-intro-modal > .modal-wrapper > .ion-page .order-intro-modal-inner {
  flex: 1 1;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}

.location-select {
  --padding-start: 0;
  margin-top: 5px;
  border-bottom: 1px solid var(--ion-color-medium);
}

.popover-content ion-item ion-label {
  margin-left: 10px;
}

.pickup-time {
  margin-top: 5px;
  border-bottom: 1px solid var(--ion-color-medium);
}

.item-content {
  margin-right: auto;
}

.app {
  display: grid;
  grid-template-columns: 20% 80%;
}
.navbar {
  padding: 0.5rem;
}
.navbar .nav-link {
  padding: 0.5rem;
}
.header {
  height: 80px;
  overflow: auto;
  background: #aaa;
}
.container {
  height: 500px;
  background: #ddd;
}
.gap {
  height: 500px;
}
.gap.short {
  height: 250px;
}
.gap.tall {
  height: 1000px;
}
.container.relative {
  overflow-y: auto;
}

.view-order-button {
  position: fixed;
  bottom: 0px;
  font-family: var(--ion-font-family);
  text-align: center;
  z-index: 1000;
  width: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.view-order-button ion-button {
  margin: 0 !important;
}

.order-list-with-button > ion-list > div:last-child > div > div:last-child > ion-item{
  padding-bottom: 40px;
}

.web .order-list-with-button > ion-list > div:last-child > div > div:last-child > ion-item{
  padding-bottom: 0px;
}

@media (max-width: 700px) {
  .app {
    grid-template-columns: unset;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  /* iOS */
  .order-list-items {
    height: 100vh;
  }
}

/* new */

.web .order-list {
  position: absolute;
  left: 0;
  top: 0;
  right: var(--okx-sidebar-width);
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.web .order-list.order-list-full {
  right: 0;
}

.web .order-summary-sidebar,
.web .order-summary-sidebar::before,
.web .order-summary-sidebar::after {
  left: calc(100% - var(--okx-sidebar-width));
}

.web .order-summary-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.web .order-summary-sidebar .add-items-row {
  display: none;
}

.web .order-summary-sidebar::before,
.web .order-summary-sidebar::after {
  content: '';
  position: fixed;
  top: -200px;
  bottom: 0;
}

.web .order-summary-sidebar::before {
  width: 20px;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web .order-summary-sidebar::after {
  width: 50px;
  background-color: #fff;
}

.web .order-summary-sidebar .absolute-content {
  top: 0;
  padding-top: 0;
}

.web .order-summary-sidebar .title {
  padding-left: 0;
  font-size: var(--okx-subtitle-font-size);
}

.web .order-summary-sidebar .checkout div.righted:first-of-type {
  visibility: hidden;
}

.web .static.route-order {
  background-color: #fff;
}

.web .order-categories {
  padding: 10px 10px 0 50px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  /* background-color: var(--ion-color-secondary); */
  z-index: 2;
}

.web .order-categories h2 {
  padding: 0;
  font-size: var(--okx-title-font-size);
}

.web .order-categories-inner {
  flex-wrap: wrap;
  padding-left: 0;
  grid-gap: 0;
  gap: 0;
}

.web .order-content {
  padding: 0 10px 0px 40px;
  height: 100vh;
}

/* grid */

.web .order-list-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: stretch;
  align-items: stretch;
  grid-row-gap: 15px;
  row-gap: 15px;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  padding: 10px;
}

.web .order-list-grid .order-list-item {
  display: block;
  width: auto;
  margin-bottom: 0;
  min-width: 0;
  background-color: #fff;
  padding: 8px;
  padding-bottom: 2px;
  /*border: 1px solid #eee;*/
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.web .order-list-grid .order-list-item .item-image {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  min-width: 0;
  min-height: 0;
  margin: 0;
  display: block;
  height: auto;
  border-radius: 0;
}

.web .order-list-grid .order-list-item .item-image .item-image-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.web .order-list-grid .order-list-item .item-bar {
  margin: 5px 0 0 0;
  display: flex;
  height: 30px;
  justify-content: space-between;
  align-items: center;
}

.web .item-bar .item-name,
.web .item-bar .item-price {
  font-size: var(--okx-small-text-font-size);
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.web .route-order .item-bar .item-price {
  text-align: right;
  align-self: center;
  flex: 0 1 50%;
}

.web .route-order .item-bar .item-name.ellipsis {
  font-weight: bold;
  white-space: normal;
  text-overflow: unset;
}

.web .item-bar .item-price {
  text-align: right;
}

.web .item-bar .item-price > span:first-child {
  color: var(--ion-color-secondary);
  font-size: inherit;
  font-weight: bold;
}

.web .item-bar .item-price > span.original-price {
  text-decoration: line-through;
  font-size: calc(var(--okx-small-text-font-size) - 1px);
}

.web ion-modal.modal-classic {
  --width: 500px;
  --height: 80%;
  min-height: 400px;
}

.web ion-modal.modal-classic .modal-classic-wrapper,
.web ion-modal.modal-classic .modal-classic-content {
  height: 100%;
  padding: 0px;
}

.web ion-modal.modal-classic .modal-classic-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
}


.web ion-modal.modal-classic .item-details-card .item-details-card-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 20px;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 1;
}

.web ion-modal.modal-classic .item-details-card .item-details-image {
  position: relative;
  grid-area: 1 / 1 / 3 / 2;
  height:-webkit-fit-content ;
  height:-moz-fit-content ;
  height:fit-content ;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 1;
}

.web ion-modal.modal-classic .item-details-card .item-details-image .item-details-image-bg {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
}

.web ion-modal.modal-classic .item-details-actions {
  margin: 0 auto;
  /* width: 100%; */
  padding-bottom: 12px;
  grid-area: 3 / 1 / -1 / -1;
  box-shadow: none;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 1;
}

.web ion-modal.modal-classic.allergen-modal,
.web ion-modal.modal-classic.apply-points-modal {
  --width: var(--okx-sidebar-width);
  --height: 100%;
  --border-radius: 0;
  justify-content: flex-end;
}

.web ion-modal.modal-classic.allergen-modal .modal-classic-wrapper,
.web ion-modal.modal-classic.apply-points-modal .modal-classic-wrapper {
  left: calc(100% - var(--okx-sidebar-width));
}

.web ion-modal.modal-classic.allergen-modal .absolute-content,
.web ion-modal.modal-classic.apply-points-modal .absolute-content {
  padding-top: 54px;
}

/* .web ion-modal.modal-classic.allergen-modal .rhino {
	background: transparent url(../../assets/images/rhino.svg) no-repeat 50% 100% / 180px auto;
} */
.web ion-modal.modal-classic.apply-points-modal .incrementer-decrease,
.web ion-modal.modal-classic.apply-points-modal .incrementer-incease {
  --color: var(--ion-color-dark);
}

.web .order-sublist-holder:last-of-type {
  margin-bottom: 0px;
}

.web ion-modal.modal-classic.apply-points-modal .incrementer {
  display: grid;
  grid-template-columns: 38px auto 38px;
  grid-column-gap: 5px;
  padding: 0;
}

.web ion-modal.modal-classic.allergen-modal .title.centered {
  text-align: left;
}

.web ion-modal.modal-classic.allergen-modal .sectiontitle {
  margin: 0;
  font-weight: bold;
}

.web ion-modal.modal-classic .modal-classic-closer {
  background: inherit;
  right: 15px;
  top: 10px;
  border-radius: 2px;
  height: 35px;
  width: 35px;
  z-index: 5;
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%);
  font-size: 15px;
  padding: 10px;
}

@media screen and (max-width: 1280px) {
  .web .order-list-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 860px) {
  .web .order-list-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 640px) {
  .web .order-list-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.image-placeholder {
  background: var(--ion-color-gray) url(/static/media/logo-main.c2db7137.png) no-repeat 50% 50%/90%;
}
